import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";
import {Link} from "react-router-dom";
import {slugify} from "../utils";
import FooterOne from "../common/footer/FooterOne";
import axios from "axios";
import Lottie from "react-lottie";
import loadingAnimation from "../assets/animations/LoadingHappyData.json";

const RenderDevice = ({device}) => {
    return <Link to={`/termekeink/hardver/${slugify(device.name)}`} className="edu-instructor-grid edu-instructor-1" style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    }}>
        <div className="edu-instructor">
            <div className="inner">
                <div className="thumbnail">
                    <a href="#">
                        <img src={device.photo}
                             alt={device.name}
                             style={{height: 180, width: 180, objectFit: "cover"}}
                        />
                    </a>
                </div>
            </div>
        </div>
        <div className="edu-instructor-info">
            <h5 className="title">
                {device.name}
            </h5>
        </div>
    </Link>
}

const DeviceSection = ({ title, devices }) => (
    <div className="edu-team-carousel-area edu-section-gapBottom">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h4 className="title h4">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="row" style={{paddingTop: "4rem"}}>
                {devices.map(device => (
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45" key={device.value}>
                        <RenderDevice device={device}/>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const HappyHardwares = () => {
    const isHomePage = window.location.pathname === "/";
    const [groupedDevices, setGroupedDevices] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchDevices = async () => {
            try {
                const response = await axios.get('https://api.happygastro.hu/api/billing/plans');
                if (response.status === 200) {
                    const devices = response.data.devices;
                    const grouped = devices.reduce((acc, device) => {
                        if (!acc[device.type]) {
                            acc[device.type] = [];
                        }
                        acc[device.type].push(device);
                        return acc;
                    }, {});
                    setGroupedDevices(grouped);
                } else {
                    console.error('Unexpected response format:', response);
                }
            } catch (error) {
                console.error('Error fetching devices:', error);
            }
            setIsLoading(false);
        };

        fetchDevices();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            {!isHomePage && <SEO title="Hardverek"/>}
            <Layout>
                {isLoading ? <div style={{
                    paddingTop: '3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                    <div style={{width: '400px'}}>
                        <Lottie options={defaultOptions}/>
                        <h3>Adatok előkészítése..</h3>
                    </div>
                </div> : <>
                <BreadcrumbOne
                    title="Hardverek"
                    rootUrl="/"
                    parentUrl="Termékeink"
                    currentUrl="Hardverek"
                />
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                    <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">Eszközöket is vásárolna?</span>
                                    <h3 className="title">Elérhető hardvereink</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {Object.keys(groupedDevices).map(type => (
                    <DeviceSection key={type} title={type} devices={groupedDevices[type]} />
                ))}
                </>}
            </Layout>
            {!isHomePage && <FooterOne/>}
        </>
    )
}

export default HappyHardwares;