import React from "react";
import {Link, useParams} from "react-router-dom";
import ReactDOM from 'react-dom';
import "./invoicePreview.scss";
import Swal from "sweetalert2";
import SEO from "../../common/SEO";
import { priceFormatter } from "../../utils";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import ScrollAnimation from "react-animate-on-scroll";

import Layout from "../../common/Layout";
import {InvoicePreview} from "./invoicePreview";
const InvoiceIndex = (props) => {
    const { orderId } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [previewImage, setPreviewImage] = React.useState(null);
    const previewRef = React.useRef(null);

    React.useEffect(() => {
        fetch(`https://api.happygastro.hu/api/invoices/get/${orderId}`)
            .then(response => response.text())
            .then(html => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, "text/html");
                const invoiceElement = doc.body;

                html2canvas(invoiceElement, {
                    width: 1050, // A4 width in pixels at 96 DPI
                    height: 1485, // A4 height in pixels at 96 DPI
                    scale: 1,
                    windowWidth: 1050,
                    windowHeight: 297,
                }).then(canvas => {
                    setPreviewImage(canvas.toDataURL("image/png"));
                    setLoading(false);
                });
            })
            .catch(err => {
                console.error("Error fetching invoice:", err);
                setLoading(false);
            });
    }, [orderId]);

    const downloadPDF = () => {
        const doc = new jsPDF("p", "mm", "a4");
        const imgData = previewImage;
        doc.addImage(imgData, "PNG", 0, 0, 210, 297);
        doc.save(`invoice_${orderId}.pdf`);
    };
  if (loading) return "Betöltés...";
  if (!loading)
    return (
      <>
          <Layout showHeader={false}>
              <SEO title={orderId ? "Számla részletei - " + orderId : "Számla betöltése..."}/>
              <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                  <div className="container eduvibe-animated-shape">
                      <div className="row g-5">
                          <div className="col-lg-8">
                              <div className="inner">
                                  <div className="inner">
                                      {previewImage ? (
                                          <img src={previewImage} alt="Számla előnézet" className="preview-window"/>
                                      ) : (
                                          <iframe ref={previewRef} security={'allow-scripts'}
                                                  src={"https://api.happygastro.hu/api/invoices/get/" + orderId}
                                                  width={'100%'} id={"invoice-preview"}
                                                  height={'100%'}/>
                                      )}
                                  </div>
                                  </div>
                              </div>

                              <div className="col-lg-4" id={"delivery-module"}>
                                  <div className="inner">
                                  <ScrollAnimation
                                      animateIn="fadeInUp"
                                      animateOut="fadeInOut"
                                      animateOnce={true}
                                  >
                                  </ScrollAnimation>
                                  <ScrollAnimation
                                      animateIn="fadeInUp"
                                      animateOut="fadeInOut"
                                      className="col-lg-12 p-0"
                                      animateOnce={true}
                                  >
                                      <div className="edu-feature-list">
                                          <div className="content d-flex justify-content-between align-items-center">

                                              <button className="edu-btn btn-medium left-icon header-button" onClick={downloadPDF}>
                                                  <i className='bx bx-cart-alt' style={{fontSize: 22, top: 5}}></i>
                                                  Számla letöltése
                                              </button>
                                          </div>
                                      </div>
                                  </ScrollAnimation>
                              </div>
                          </div>
                      </div>

                      <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                          <div className="shape-image shape-image-1">
                              <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                          </div>
                          <div className="shape-image shape-image-2">
                              <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                          </div>
                          <div className="shape-image shape-image-3">
                              <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                          </div>
                          <div className="shape-image shape-image-4">
                              <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                          </div>
                      </div>
                  </div>
              </div>
          </Layout>
      </>
    );
};

export default InvoiceIndex;
