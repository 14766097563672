import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import axios from "axios";
import serialize from "form-serialize";
import FooterOne from "../../common/footer/FooterOne";

const Result = ({ result, setResult }) => {
    return (
        <div>
            <div className="alert alert-success text-center" role="alert">
                <h4 className="alert-heading text-center" style={{fontSize: '2.4rem'}}>Köszönjük üzenetét, hamarosan keresni fogjuk!</h4>
            </div>
            <br />
            <br />
            <p>
                Köszönjük, hogy kérdésével felkereste ügyfélszolgálatunkat.<br/><strong>Bejelentését a {result} jegyszámon tartjuk nyilván.<br/>
                Telefonos érdeklõdés esetén kérjük ezt a kódot megadni.</strong>
                <br/>
                <br/>
                    Kollégáink megvizsgálják a beküldött üzenetet és annak megfelelõen keresni fogják Önt e-mailben vagy telefonon.
                <br/>
                <br/>
                További kérdés esetén készséggel állunk rendelkezésére a <a href={'tel:+3613009105'}>+36-1-300-91-05</a>-ös telefonszámon vagy e-mailben <a href={'mailto:support@happygastro.hu'} target={'_blank'} rel="noreferrer">support@happygastro.hu</a> címen.
            </p>
            <br />
            <div className="col-lg-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <button className="rn-btn edu-btn w-100" type="submit" onClick={() => setResult(false)}>
                    <i className="icon-arrow-left-line-right"></i><span>Új üzenet írása</span></button>
            </div>
        </div>
    )
}

function ContactUsForm( { formStyle, modalMode = false } ) {
    const [result, setResult] = useState( false );

    const sendEmail = ( e ) => {
        e.preventDefault();

        // Ensure the form has the correct id
        let form = document.querySelector('#contact-us-form-');
        if (modalMode) {
            form = document.querySelector('#contact-us-form-fromModalFrom');
        }
        if (!form) {
            console.error("Form with id 'contact-us-form-' or 'contact-us-form-fromModalFrom' not found.");
            return;
        }

        // Serialize the form data
        let formData = serialize(form, { hash: true, empty: true });
        //debugg log
        // console.log("Serialized formData:", formData);

        // Check if formData is empty
        if (Object.keys(formData).length === 0) {
            console.error("Form data is empty. Ensure all input fields have the correct 'name' attributes.");
            return;
        }

        // Validate phone number
        const phoneNumber = formData.phone.replace(/\D/g, ''); // Remove non-digit characters
        if (phoneNumber.length !== 10 && phoneNumber.length !== 11) {
            alert('A telefonszám hossza nem megfelelő. Kérjük, adjon meg egy 10 vagy 11 számjegyű telefonszámot.');
            return;
        }
        // Validate email
        if (formData.email.includes('@') && formData.email.includes('.')) {
            const atIndex = formData.email.indexOf('@');
            const dotIndex = formData.email.lastIndexOf('.');
            if (atIndex === -1 || dotIndex === -1 || dotIndex <= atIndex + 1) {
                alert('Az email cím formátuma nem megfelelő. Kérjük, adjon meg egy érvényes email címet.');
                return;
            }
        }else{
            alert('Az email cím formátuma nem megfelelő. Kérjük, adjon meg egy érvényes email címet.');
            return;
        }
        //debugg log
        // console.log("data", {
        //              ...formData,
        //              origin: 'happygastro.hu',
        //              companyData: {
        //                  nev: formData.company || ""
        //              },
        //              company: formData.company || "",
        //          })

        axios({
            method: 'post',
            url: `https://api.happygastro.org/api/contact/submit`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                ...formData,
                origin: 'happygastro.hu',
                companyData: {
                    nev: formData.company || ""
                },
                company: formData.company || "",
            }
        })
            .then(res => {
                e.target.reset();
                setResult(res.data.code)
            })
            .catch(error => {
                setResult(false)
                alert('Sajnáljuk, de nem várt technikai hiba lépett fel. Kérjük prôbálja újra késöbb, vagy keressen minket ügyfélszolgálati vonalainkon bármelyikén!!')
            })
    };

    let idMod = "";
    if (modalMode) {
        idMod = "fromModalFrom"
    }
    return (
        <div>
            {result ? <Result setResult={setResult} result={result} /> : <form id={`contact-us-form-${idMod}`} className={`${formStyle}`} action="" onSubmit={sendEmail}>
                <div className="col-lg-12">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="fullname" placeholder="Teljes neve*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="company" placeholder="Cég / Étterem neve"  />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <InputMask
                        className="form-control form-control-lg"
                        autoComplete="new-password"
                        name="phone"
                        id="phone"
                        mask="+36 (99) 999-9999"
                        placeholder="Telefonszám*"
                        required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="subject" placeholder="Üzenet tárgya" required />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                    <textarea className="form-control" name="message" placeholder="Üzenete, vagy észrevétele" required></textarea>
                </div>
            </div>
            <div className="col-lg-12">
                <button className="rn-btn edu-btn w-100" type="submit">
                    <span>Űrlap beküldése</span><i className="icon-arrow-right-line-right"></i></button>
            </div>
        </form>}
        </div>
    )
}
export default ContactUsForm;