import React, { useState } from "react";
import SEO from "../../src/common/SEO";
import Layout from "../../src/common/Layout";
import BreadcrumbOne from "../../src/common/breadcrumb/BreadcrumbOne";
import Packages from "./components/Packages";
import Devices from "./components/Devices";
import SummaryModal from "./components/SummaryModal";
import Questions from "./components/Questions";
import axios from "axios";
import { useHappyContext } from "../context/happy_context";
import Swal from "sweetalert2";
import FooterOne from "../common/footer/FooterOne";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import happyHardwaresData from "../data/happyHardwaresData/HappyHardwaresData.json";
import t from "react-infinite-logo-slider";

const PricingPlan = ({ formStyle }) => {
  const { isLoggedIn } = useHappyContext();
  const vatKey = 1.0;
  const [selectedPackage, setSelectedPackage] = useState("");
  const [tableOrdersSelected, setTableOrdersSelected] = useState(true);
  const [onlineOrdersSelected, setOnlineOrdersSelected] = useState(false);
  const [stripeCanMount, setStripeCanMount] = React.useState(false);
  const {
    tabletsWindowsDevices,
    printers,
    cashRegisters,
    callers,
    barcodeScanners,
  } = happyHardwaresData;

  const [packageData, setPackageData] = useState({
    img: "https://happy-releases.fra1.digitaloceanspaces.com/Uploads/HappyWeb/images/PricingPlan/D1-banner-1.png",
    name: "iMin D1 Pro",
    description:
      '13,3" kijelző, kis méretű POS terminál, beépített nyomtatóva, bankkártyaolvasóval és segédkijelzővel',
    price: 220000,
    quantity: 0,
  });
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [softwareQuantity, setSoftwareQuantity] = React.useState(1);
  const [selectedMonths, setSelectedMonths] = React.useState(1);
  const [showPackages, setShowPackages] = React.useState(true);
  const [isSendOfferEmail, setIsSendOfferEmail] = React.useState(false);
  const [isPackageClicked, setIsPackageClicked] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [devices, setDevices] = React.useState([
    ...tabletsWindowsDevices,
    ...printers,
    ...cashRegisters,
    ...callers,
    ...barcodeScanners,
    /* {
      img: "https://happy-releases.fra1.digitaloceanspaces.com/Uploads/HappyWeb/images/PricingPlan/iminm2pro-0.png",
      name: "iMin M2 Pro",
      description: "Kézi tablet beépített bankkártyaolvasóval és nyomtatóval",
      price: 99900,
      quantity: 0,
    },
    {
      img: "https://happy-releases.fra1.digitaloceanspaces.com/Uploads/HappyWeb/images/PricingPlan/D1-banner-1.png",
      name: "iMin D1 Pro",
      description:
        '13,3" kijelző, kis méretű POS terminál, beépített nyomtatóva, bankkártyaolvasóval és segédkijelzővel',
      price: 220000,
      quantity: 0,
    },
    {
      img: "https://happy-releases.fra1.digitaloceanspaces.com/Uploads/HappyWeb/images/PricingPlan/Bix330-no-bg.png",
      name: "Bixolon SRP-330 II",
      description:
        "Klasszikus formára építkező modernizált dizájn, megbízható működés és kitűnő ár-érték arány jellemzi az SRP-330II blokknyomtatót.",
      price: 64000,
      quantity: 0,
    }, */
  ]);
  const [showRegisterModal, setShowRegisterModal] = React.useState(false);
  const packages = [
    /*  {
                  packageName: 'TESZT',
                  description: 'teszt',
                  price: 5
              },*/
    {
      packageName: "Mini",
      description: "Csak a gyorsnyugta",
      price: 9900,
      url: `https://happygastro.lemonsqueezy.com/checkout/buy/30ede752-5bea-4cae-8398-928607d7527f?embed=1&quantity=${softwareQuantity}`,
    },
    {
      packageName: "Alap",
      description: "Mini plusz egy modul, házhoz vagy asztalos",
      price: 14990,
      url: `https://happygastro.lemonsqueezy.com/checkout/buy/080e30b8-6f62-48c5-9e58-510c74c31acd?embed=1&quantity=${softwareQuantity}`,
    },
    {
      packageName: "Prémium",
      description: "Minden fő modul, házhoz meg asztalos",
      price: 19900,
      url: `https://happygastro.lemonsqueezy.com/checkout/buy/959de4fe-19e3-4e1d-a125-b97239223e31?embed=1&quantity=${softwareQuantity}`,
    },
  ];
  const getDiscount = () => {
    if (selectedMonths < 3) return 0;
    if (selectedMonths >= 3 && selectedMonths < 6) return 3;
    if (selectedMonths >= 6 && selectedMonths < 12) return 6;
    if (selectedMonths === 12) return 10;
  };
  const order = {
    devices: [...devices].filter((device) => device.quantity > 0),
    package: {
      ...packageData,
      months: selectedMonths,
      //discount: getDiscount()
    },
  };

  const [modules, setModules] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [moduleTableContent, setModuleTableContent] = React.useState(null);
  const [oldModules, setOldModules] = React.useState([

      ])
  const api = axios.create({
    baseURL: 'https://api.happygastro.hu',
    headers: {
      'Content-Type': 'application/json',
    },
  });


  const fetchBillingPlans = async () => {
    console.log("fetch")

    try {
      const response = await api.get('/api/billing/plans');
      console.log(response.status)
      console.log(response.data)
      setOldModules([...oldModules, {
        feature: "Munkatársak száma",
        miniPackage: "max "+ response.data.plans[1].maxEmployees,
        basicPackage: "max "+ response.data.plans[2].maxEmployees,
        premiumPackage: "korlátlan",
        extraModule:
        /*"Itt lehetnee, hogy minden további munkatárs +990 Ft peldaul",*/ "",
      },
        {
        feature: "Asztalos rendelések/felütések",
        miniPackage: false,
        basicPackage: "Asztalos VAGY Házhozszállítás",
        premiumPackage: true,
        extraModule: "",
      },
        {
          feature: "Házhozszállítás",
          miniPackage: false,
          basicPackage: "Asztalos VAGY Házhozszállítás",
          premiumPackage: true,
          extraModule: "",
        },])
      if (response.status === 200) {
        const { plans, licenses } = response.data;
        let modulesToPush = []
        let servicesToPush = []
        licenses.map((license) => {
              if(license.type === 'service'){
                servicesToPush = [...servicesToPush, license]
              }else{
                modulesToPush = [...modulesToPush, license]
              }
            }
        )
        console.log("[...modules, ...modulesToPush]")
        console.log([...modules, ...modulesToPush])
        setModules([...modules, ...modulesToPush])
        setServices([...services, ...servicesToPush])
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching billing plans:', error);
    }
  };

  React.useEffect(() => {
    fetchBillingPlans();
  }, []);

  React.useEffect(() => {
    if(modules){
      let newModuleTableContent = [];
      modules.map((module, index) => {
        if(index !== 0) {
          if (module?.includedIn?.length !== 0) {
            newModuleTableContent.push(
                {
                  feature: module.name,
                  miniPackage: module.includedIn?.includes("minimal"),
                  basicPackage: module.includedIn?.includes("normal"),
                  premiumPackage: module.includedIn?.includes("premium"),
                  extraModule: ""
                }
            )
          }
        }});
      setModuleTableContent(newModuleTableContent);
    }


  }, [modules]);



  const modulesData = [
    {
      feature: "Étlap feltöltése",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Távoli hozzáférés",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "NTAK adattovábbítás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Automatikus blokk nyomtatás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Napi zárás részletesen",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Munkatársak száma",
      miniPackage: "max 3",
      basicPackage: "max 8",
      premiumPackage: "korlátlan",
      extraModule:
        /*"Itt lehetnee, hogy minden további munkatárs +990 Ft peldaul",*/ "",
    },
    {
      feature: "Jogosultság kezelése",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Gyors kiszolgálás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Asztalos rendelések/felütések",
      miniPackage: false,
      basicPackage: (
        <>
          <span>Ezt választom</span>
          <Checkbox
            checked={tableOrdersSelected}
            onChange={(e) => {
              setTableOrdersSelected(true);
              setOnlineOrdersSelected(false);
            }}
          />
        </>
      ),
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Házhozszállítás",
      miniPackage: false,
      basicPackage: (
        <>
          <span>Ezt választom</span>
          <Checkbox
            checked={onlineOrdersSelected}
            onChange={(e) => {
              setOnlineOrdersSelected(true);
              setTableOrdersSelected(false);
            }}
          />
        </>
      ),
      premiumPackage: true,
      extraModule: "",
    },
  ];

  const sendOfferEmail = () => {
    return axios({
      method: "post",
      url: `${window.api}/company/sendOfferEmail`,
      headers: { "Content-Type": "application/json" },
      data: {
        email: localStorage.getItem("email"),
        packageData: packageData,
        devices: devices,
      },
    })
      .then((res) => {
        if (res)
          Swal.fire({
            title: "Árajánlat elküldve!",
            confirmButtonColor: "#f1b44c",
          }).then((res) => {
            setIsSendOfferEmail(false);
          });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Hiba történt!",
          confirmButtonColor: "#f1b44c",
        });
      });
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);
  React.useEffect(() => {
    if (loaded && isSendOfferEmail) {
      if (isLoggedIn) sendOfferEmail();
      else setShowRegisterModal(true);
    }
  }, [isSendOfferEmail]);
  React.useEffect(() => {
    // eslint-disable-next-line default-case
    switch (selectedPackage) {
      case "":
        return setPackageData({});
      case "TESZT":
        return setPackageData({
          packageName: "TESZT",
          description: "teszt",
          price: 5,
        });
      case "Mini":
        return setPackageData({
          packageName: "Mini",
          description: "Csak a gyorsnyugta",
          price: 9000,
          url: `https://happygastro.lemonsqueezy.com/checkout/buy/b3c68ca4-9d4f-4445-a935-f2614e2a7141?embed=1&quantity=${softwareQuantity}`,
        });
      case "Alap":
        return setPackageData({
          packageName: "Alap",
          description: "Mini plusz egy modul, házhoz vagy asztalos",
          price: 15000,
          url: `https://happygastro.lemonsqueezy.com/checkout/buy/4cd6aabd-8fea-49d7-9aa5-0c064017193b?embed=1&quantity=${softwareQuantity}`,
        });
      case "Prémium":
        return setPackageData({
          packageName: "Prémium",
          description: "Minden fő modul, házhoz meg asztalos",
          price: 20000,
          url: `https://happygastro.lemonsqueezy.com/checkout/buy/a7f074da-3d6c-44e9-aeab-6a34d234ee2c?embed=1&quantity=${softwareQuantity}`,
        });
    }
  }, [selectedPackage]);

  return (
    <>
      <SEO
        title="Árajánlat"
        description={
          "Csomagjaink, moduljaink és eszközeink árai - árajánlat kérése, demó igénylése"
        }
      />
      <Layout>
        {false && (
          <BreadcrumbOne
            title="Árajánlat"
            rootUrl="/"
            parentUrl="Szolgáltatások"
            currentUrl="Árajánlat"
          />
        )}
        <div
          className="eduvibe-contact-us edu-contact-us-area bg-color-white"
          style={{ padding: "4rem 0" }}
        >
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                {showPackages && (
                  <Packages
                    packages={packages}
                    selectedPackage={selectedPackage}
                    setSelectedPackage={(value) => setSelectedPackage(value)}
                    softwareQuantity={softwareQuantity}
                    setSoftwareQuantity={(value) => setSoftwareQuantity(value)}
                    selectedMonths={selectedMonths}
                    setSelectedMonths={(value) => setSelectedMonths(value)}
                    setShowRegisterModal={(value) =>
                      setShowRegisterModal(value)
                    }
                    setIsPackageClicked={(value) => setIsPackageClicked(value)}
                  />
                )}
              </div>
            </div>

            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="title">Modulok</h3>
                  <span className="pre-title">További modulok</span>
                </div>
              </div>
            </div>
            <div
                className={"table-wrapper eduvibe-animated-shape"}
                style={{maxWidth: 850, margin: "2rem auto 4rem auto"}}
            >
              <table
                  className={"module-prices-table"}
                  aria-label="simple table"
              >
                <thead className={`head sticky`}>
                <tr>
                  <th></th>
                  <th align="right">Mini csomag</th>
                  <th align="right">Alap csomag</th>
                  <th align="right">Prémium csomag</th>
                </tr>
                </thead>
                <tbody>
                {oldModules?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.feature}</td>
                      <td align="right">
                        {typeof row.miniPackage === "boolean" ? (
                            row.miniPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.miniPackage
                        )}
                      </td>
                      <td align="right">
                        {typeof row.basicPackage === "boolean" ? (
                            row.basicPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.basicPackage
                        )}
                      </td>
                      <td align="right">
                        {typeof row.premiumPackage === "boolean" ? (
                            row.premiumPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.premiumPackage
                        )}
                      </td>
                    </tr>
                ))}
                  {moduleTableContent?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.feature}</td>
                      <td align="right">
                        {typeof row.miniPackage === "boolean" ? (
                            row.miniPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.miniPackage
                        )}
                      </td>
                      <td align="right">
                        {typeof row.basicPackage === "boolean" ? (
                            row.basicPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.basicPackage
                        )}
                      </td>
                      <td align="right">
                        {typeof row.premiumPackage === "boolean" ? (
                            row.premiumPackage ? (
                                <CheckIcon/>
                            ) : (
                                <CloseIcon/>
                            )
                        ) : (
                            row.premiumPackage
                        )}
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
            <div className="row g-5" style={{padding: "4rem 0"}}>
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">
                    <Link
                      to="/szolgaltatasok/arajanlat/modul-arak"
                      style={{
                        color: "#f1b44c",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setTimeout(() => {
                          const extrasList =
                            document.querySelector(".extras-list");
                          window.scrollTo(
                            0,
                            extrasList ? extrasList.offsetTop - 150 : 600
                          );
                        }, 1000);
                      }}
                    >
                      További választható moduljaink
                      <button className="rn-btn edu-btn w-50" type="submit">
                        <span>Megnézem</span>
                      </button>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="title">Árajánlat</h3>
                  <span className="pre-title">Válaszoljon néhány kérdésre</span>
                </div>
              </div>
            </div>
            {
              <div style={{ margin: "2rem 0 0 0" }}>
                <Questions
                  formStyle={formStyle}
                  setSelectedPackage={(value) => setSelectedPackage(value)}
                  isSendOfferEmail={isSendOfferEmail}
                  setIsSendOfferEmail={(value) => setIsSendOfferEmail(value)}
                />
              </div>
            }

            <div className="row g-5" style={{ paddingTop: "5rem" }}>
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="title">Egyedi igényei vannak?</h3>
                  <span className="pre-title">
                    Írja meg nekünk, miben más az Ön étterme!
                  </span>
                </div>
              </div>
              <div
                className="col-lg-12 d-flex justify-content-center"
                style={{ padding: "2rem 0" }}
              >
                <textarea
                  name="unique-restaurant"
                  id="unique-restaurant"
                  rows={5}
                  style={{ maxWidth: "50rem", margin: "0 auto" }}
                ></textarea>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <button className="rn-btn edu-btn w-25" type="submit">
                  <span>Küldés</span>
                  <i className="icon-arrow-right-line-right"></i>
                </button>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <React.Fragment>
            <Devices
              vatKey={vatKey}
              devices={devices}
              setDevices={(value) => setDevices(value)}
            />
            {false && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "4rem",
                }}
              >
                <button
                  className="rn-btn edu-btn"
                  onClick={() => {
                    setShowSummaryModal(true);
                    if (isLoggedIn) setStripeCanMount(true);
                    //setShowRegisterModal(true)
                  }}
                >
                  Megrendelem
                </button>
              </div>
            )}
          </React.Fragment>
          <SummaryModal
            showRegisterModal={showRegisterModal}
            setShowRegisterModal={(value) => setShowRegisterModal(value)}
            showSummaryModal={showSummaryModal}
            setShowSummaryModal={(value) => setShowSummaryModal(value)}
            packageData={packageData}
            softwareQuantity={softwareQuantity}
            selectedMonths={selectedMonths}
            devices={devices}
            setShowPackages={(value) => setShowPackages(value)}
            showPackages={showPackages}
            isSendOfferEmail={isSendOfferEmail}
            setIsSendOfferEmail={(value) => setIsSendOfferEmail(value)}
            stripeCanMount={stripeCanMount}
            setStripeCanMount={(value) => setStripeCanMount(value)}
            vatKey={vatKey}
            isPackageClicked={isPackageClicked}
            setIsPackageClicked={(value) => setIsPackageClicked(value)}
          />
        </div>
        <FooterOne />
      </Layout>
    </>
  );
};
export default PricingPlan;
