import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const Rights = (props) => {
  const isHomePage =
    window.location.pathname === "/" ||
    window.location.pathname === "/termekeink/szoftver";
  return (
    <Layout>
      {!props.fromHome && (
        <SEO
          title={"Jogosultságok kezelése"}
          description={
            " A munkatársak hozzáférési jogosultságajogosultssága csoportokba rendezhetőekrendzehető, így azonos modulokat használhatnak, de az egyedi hozzáférési felhatalmazás adható. A munkatársak hatáskörei észszerűen, a feladatukhoz alkalmazkodva optimalizálhatók a működési adatok biztonsága érdekében."
          }
        />
      )}
      <BreadcrumbOne
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1579447167432-ba8b796e5de1?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
        }}
        title={"<font style='color: white'>Jogosultságok kezelése</font>"}
        rootUrl="/"
        parentUrl={"<font style='color: white'>Termékeink</font>"}
        currentUrl={"<font style='color: white'>Jogosultságok kezelése</font>"}
      />
      <div className="edu-about-area about-style-3 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                <SectionTitle
                  classes="text-start"
                  slogan="Hozzáférés-kezelés a biztonságosabb működésért!"
                  title="Jogosultságok kezelése"
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description mt--40">
                    A munkatársak hozzáférési jogosultságajogosultssága
                    csoportokba rendezhetőekrendzehető, így azonos modulokat
                    használhatnak, de az egyedi hozzáférési felhatalmazás
                    adható. A munkatársak hatáskörei észszerűen, a feladatukhoz
                    alkalmazkodva optimalizálhatók a működési adatok biztonsága
                    érdekében.
                  </p>
                </ScrollAnimation>
              </div>
            </div>

            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Csoportos jogosultságkezelés
                      </h6>
                      <p className="description">Hatékonyabb adminisztráció.</p>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Egyedi hozzáférési szintek
                      </h6>
                      <p className="description">Biztonságos adatkezelés.</p>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Feladatokhoz igazított hatáskörök
                      </h6>
                      <p className="description">Optimalizált munkavégzés.</p>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        A Happy Gastro
                      </h6>
                      <p className="description">
                        A megbízható működés alapja!
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
      {!isHomePage && <FooterOne />}
    </Layout>
  );
};

export default Rights;
