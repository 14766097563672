import React from "react";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Slider from "@mui/material/Slider";
import { priceFormatter } from "../../utils";
import { useHappyContext } from "../../context/happy_context";

const Packages = ({
  packages,
  selectedPackage,
  setSelectedPackage,
  softwareQuantity,
  setSoftwareQuantity,
  selectedMonths,
  setSelectedMonths,
  setShowRegisterModal,
  setIsPackageClicked,
}) => {
  const { isLoggedIn } = useHappyContext();

  const marks = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));

  function valuetext(value) {
    return `${value} hónap`;
  }

  const onSliderChange = (value) => {
    if (!value || !value.target.value) return;
    setSelectedMonths(value.target.value);
  };
  const getDiscount = () => {
    if (selectedMonths < 3) return 0;
    if (selectedMonths >= 3 && selectedMonths < 6) return "3%";
    if (selectedMonths >= 6 && selectedMonths < 12) return "6%";
    if (selectedMonths === 12) return "10%";
  };
  const getPrice = (price) => {
    if (selectedMonths < 3) return price * softwareQuantity;
    if (selectedMonths >= 3 && selectedMonths < 6)
      return (price - price * 0.03) * softwareQuantity;
    if (selectedMonths >= 6 && selectedMonths < 12)
      return (price - price * 0.06) * softwareQuantity;
    if (selectedMonths === 12) return (price - price * 0.1) * softwareQuantity;
  };
  /*
    <div className="row">
                                <div className={'col'}
                                     style={{fontSize: '2.1rem', textAlign: 'center', paddingTop: '2rem'}}>
                                    <span>A csomag ára: <br/></span>
                                    <h5>
                                        {selectedMonths >= 3 && <>
                                            <small style={{fontSize: '1.5rem', fontWeight: '450', opacity: '.85'}}>
                                                Kedvezmény: {getDiscount()}
                                            </small>
                                            <br/>
                                        </>}
                                        {priceFormatter(getPrice(data.price))} / hónap <br/>
                                        {selectedMonths > 1 && <>Összesen: {priceFormatter(getPrice(data.price * selectedMonths))}</>}
                                    </h5>
                                </div>
                            </div>
     */
  return (
    <div
      className="eduvibe-home-four-testimonial edu-testimonial-area"
      style={{ paddingTop: "1.5rem", position: "relative" }}
    >
      <div className="edu-pricing-area bg-image" style={{ padding: "2rem 0" }}>
        <div className="container">
          <div className="row g-5">
            <div className="col-12">
              <div className="section-title text-center">
                <span className="pre-title">
                  Lehetőség van több csomag közül is választani
                </span>
                <h3 className="title">Válassza ki az Önnek megfelelőt</h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--40">
            {packages.map((data, id) => {
              return (
                <div key={id} className="col-lg-4 col-md-6 col-12">
                  <div
                    className={`pricing-table ${
                      data.packageName === selectedPackage ? " active" : ""
                    }`}
                    style={{
                      cursor: "pointer",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedPackage(data.packageName);
                      /* if (isLoggedIn) window.open(data.url, "_blank");
                      else {
                        setIsPackageClicked(true);
                        setShowRegisterModal(true);
                      } */
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="pricing-header">
                        {data.packageName === selectedPackage && (
                          <div className="edu-badge">
                            <span>{data.badgeText}</span>
                          </div>
                        )}
                        <h3 className="title">{data.packageName}</h3>
                        <div className="price-wrap">
                          <div className="yearly-pricing">
                            <span className="amount">
                              {priceFormatter(getPrice(data.price))}
                            </span>
                            <span className="duration">/hónap</span>
                          </div>
                        </div>
                      </div>
                      <div className="about-feature-list">
                        <div
                          className="our-feature sal-animate"
                          data-sal-delay="150"
                          data-sal="slide-up"
                          data-sal-duration="800"
                        >
                          <div
                            className="icon"
                            style={{ backgroundColor: "#000", color: "white" }}
                          >
                            <ReceiptLongIcon
                              color={"inherit"}
                              sfontSize={"large"}
                            />
                          </div>
                          <div className="feature-content d-flex justify-content-center align-items-center">
                            <h6 className="feature-title m-0">Gyors nyugta</h6>
                          </div>
                        </div>
                        {data.packageName !== "Mini" && (
                          <div
                            className="our-feature sal-animate"
                            data-sal-delay="150"
                            data-sal="slide-up"
                            data-sal-duration="800"
                          >
                            <div
                              className="icon"
                              style={{
                                backgroundColor: "#7EC0FCFF",
                                color: "white",
                              }}
                            >
                              <i className="fa-solid fa-chair"></i>
                            </div>
                            <div className="feature-content d-flex justify-content-center align-items-center">
                              <h6 className="feature-title m-0">Asztaltérkép</h6>
                            </div>
                          </div>
                        )}

                        {data.packageName === "Alap" && (
                          <div
                            className="row justify-content-center"
                            style={{
                              fontSize: "1.75rem",
                              textAlign: "center",
                              padding: "1rem 0",
                            }}
                          >
                            <strong style={{ color: "var(--color-dark)" }}>
                              VAGY
                            </strong>
                          </div>
                        )}
                        {data.packageName !== "Mini" && (
                          <div
                            className="our-feature sal-animate"
                            data-sal-delay="150"
                            data-sal="slide-up"
                            data-sal-duration="800"
                          >
                            <div
                              className="icon"
                              style={{
                                backgroundColor: "#F7CC45",
                                color: "white",
                              }}
                            >
                              <i className="fa-solid fa-person-biking"></i>
                            </div>
                            <div className="feature-content d-flex justify-content-center align-items-center">
                              <h6 className="feature-title m-0">Házhozszállítás</h6>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {false && (
                      <div
                        className="pricing-btn d-flex justify-content-center"
                        style={{ paddingTop: "2rem" }}
                      >
                        <button
                          className={`edu-btn ${
                            data.packageName === selectedPackage
                              ? " btn-dark"
                              : ""
                          }`}
                          href={"#"}
                          onClick={() => {
                            if (isLoggedIn) window.open(data.url, "_blank");
                            else {
                              setIsPackageClicked(true);
                              setShowRegisterModal(true);
                            }
                          }}
                        >
                          Ezt választom
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row g-5 mt--40 flex-column flex-md-row">
            {false && (
              <div
                className={"col-12 col-md-6"}
                style={{
                  fontSize: "2.1rem",
                  textAlign: "center",
                  paddingTop: "2rem",
                }}
              >
                <div style={{ marginBottom: "2rem" }}>
                  <span>Hány hónapra szeretne előfizetni?</span>
                </div>
                <div
                  style={{
                    padding: "0 15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slider
                    aria-label="Temperature"
                    defaultValue={1}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="true"
                    step={1}
                    min={1}
                    max={12}
                    onChange={onSliderChange}
                    marks={marks}
                  />
                </div>
              </div>
            )}
            {false && (
              <div
                className={"col-12 col-md-6 col-md-12"}
                style={{ textAlign: "center", paddingTop: "2rem" }}
              >
                <div style={{ paddingBottom: "1rem" }}>
                  <span style={{ fontSize: "1.85rem", whiteSpace: "nowrap" }}>
                    Hány eszközre szeretné a programot?
                  </span>
                </div>
                <form>
                  <fieldset data-quantity="" style={{ margin: "0 auto" }}>
                    <legend>Change quantity</legend>
                    <button
                      type="button"
                      title="Csökkentés"
                      className="sub"
                      onClick={() => {
                        if (softwareQuantity >= 0) {
                          setSoftwareQuantity(softwareQuantity - 1);
                        }
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      name="quantity"
                      min="0"
                      value={softwareQuantity}
                      onChange={(e) => {}}
                    />
                    <button
                      type="button"
                      title="Növelés"
                      className="add"
                      onClick={() => {
                        setSoftwareQuantity(softwareQuantity + 1);
                      }}
                    >
                      +
                    </button>
                  </fieldset>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
