import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";
import Layout from "../common/Layout";
import SEO from "../common/SEO";

const Profile = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    const data = [
        {
            title: "Étterem",
            img: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Az éttermek számára fejlesztett szoftverünk teljes körű megoldást kínál\n" +
                "                                                az\n" +
                "                                                éttermi menedzsmentre. Kezelheted az étellistát, a foglalásokat, a\n" +
                "                                                készletet és\n" +
                "                                                a számlázást egy könnyen használható felületen keresztül.",
            reversed: false
        },
        {
            title: "Büfé",
            img: "https://images.unsplash.com/photo-1565123409695-7b5ef63a2efb?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Szoftverünk lehetővé teszi a " +
                "gyors és hatékony rendelésfelvételt és számlázást büfék számára is. Rugalmasan alkalmazkodik a " +
                "büfé különféle kínálatához és árazásához.",
            reversed: true
        },
        {
            title: "Kávézó",
            img: "https://images.unsplash.com/photo-1554118811-1e0d58224f24?q=80&w=3247&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "A kávézókban a vendégek gyakran szeretik a hűvös és meleg italok széles választékát. " +
                "A szoftverünk lehetővé teszi a kávézók számára, hogy könnyen kezeljék a kávékülönlegességeket, a " +
                "reggeli menüt és a vendégek hűségprogramjait.",
            reversed: false
        },
        {
            title: "Italüzlet, Bár, Söröző",
            img: "https://images.unsplash.com/photo-1597290282695-edc43d0e7129?q=80&w=3275&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Az italüzleteknek és bároknak szánt szoftverünk segít az italok nyomon követésében, " +
                "az étlap kezelésében és a fogyasztások gyors és pontos számlázásában.",
            reversed: true
        },
        {
            title: "Szórakozóhely",
            img: "https://images.unsplash.com/photo-1574879948818-1cfda7aa5b1a?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Szórakozóhelyeken a szórakozás mellett fontos a hatékony üzemeltetés.",
            reversed: false
        },
        {
            title: "Közétkeztetés",
            img: "https://images.unsplash.com/photo-1567521464027-f127ff144326?q=80&w=3348&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Az iskolákban és vállalatoknál a közétkeztetés szoftverünkkel könnyen nyomon követhető és tervezhető. " +
                "Menük kezelése, diéták figyelembevétele és a vendéglátás adminisztrációja mind egyszerűvé válik szoftverünkkel.",
            reversed: true
        },
        {
            title: "Gyors étterem",
            img: "https://images.unsplash.com/photo-1552644607-25fc193d809a?q=80&w=3024&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Gyorséttermek számára kínált szoftverünk lehetővé teszi a gyors és hatékony rendelésfelvételt, a készlet kezelését és a gyorsétteremláncok központi irányítását.",
            reversed: false
        },
        {
            title: "Rendezvény étkeztetés",
            img: "https://images.unsplash.com/photo-1511795409834-ef04bbd61622?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Szoftverünk lehetővé teszi a rendezvények testre szabott menüjének kezelését, a " +
                "vendégek számának nyomon követését és a rendezvények logisztikájának tervezését.",
            reversed: true
        },
        {
            title: "Mozgó vendéglátás",
            img: "https://images.unsplash.com/photo-1563861019306-9cccb83bdf0c?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Szoftverünk segít a mozgó egységek menedzselésében, a " +
                "helyszíni rendelések kezelésében és a vendégek elkötelezettségének növelésében is.",
            reversed: false
        },
        {
            title: "Alkalmi és szezonális vendéglátás",
            img: "https://images.unsplash.com/photo-1505826759037-406b40feb4cd?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Az ünnepek és események alkalmával a szoftverünk segít a speciális menük kezelésében, a " +
                "vendégek fogadásában és a rövid időszakokban történő kiemelt kiszolgálásban.",
            reversed: true
        },
        {
            title: "Cukrászda, Pékség",
            img: "https://images.unsplash.com/photo-1587241321921-91a834d6d191?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: "Szoftverünk segít a termékek receptúráinak kezelésében, " +
                "a sütemények és péksütemények rendelésfelvételében és az üzleti folyamatok hatékony irányításában.",
            reversed: false
        }
    ]
    const handleClick = (event, title) => {
        event.preventDefault();
        const element = document.getElementById(title);
        if (element) {
            const offsetPosition = element.offsetTop; // Change 100 to your desired offset
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
            //window.location.hash = `#${title}`;
        }
    };

    return (
        <Layout>
            {!isHomePage && <SEO title="Profilom"/>}
            {false && <BreadcrumbOne
                style={{backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-module-banner.jpg')`}}
                title={"<font style='color: white'>Profilom</font>"}
                rootUrl="/"
                parentUrl={"<font style='color: white'>Profilom</font>"}
            />}

            <div className="edu-about-area about-style-3 bg-image">
                <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                    <div className="container eduvibe-animated-shape d-flex" style={{flexWrap: 'wrap', gap: '1.5rem'}}>
                        {data.map(data => {
                            return (
                                <div className="breadcrumb-inner text-start d-flex" key={data.title}>
                                    <i className="ri-arrow-drop-right-line"></i>
                                    <div className="page-title">
                                        <span className="title" style={{whiteSpace: 'nowrap'}}
                                           onClick={(event)=>{handleClick(event, data.title)}}
                                           dangerouslySetInnerHTML={{__html: data.title}}></span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="container eduvibe-animated-shape">
                    {data.map(row => {
                        return (
                            <div className="row g-5" id={row.title}
                                 style={{flexDirection: !row.reversed ? "row" : "row-reverse", alignItems: 'center'}}>
                                <div className="col-lg-6" id={"delivery-module"}>
                                    <div className="inner">
                                        <SectionTitle
                                            classes="text-start"
                                            title={row.title}
                                        />
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                        >
                                            <p className="description mt--40">
                                                {row.description}
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="col-lg-6" id={"delivery-module"}>
                                    <div className="inner">
                                        <ScrollAnimation
                                            animateIn="fadeIn"
                                            animateOut="fadeInOut"
                                            className="testimonial-left-image"
                                            animateOnce={true}
                                            style={{margin: '5rem 0'}}
                                        >
                                            <div className="thumbnail">
                                                <div className="circle-image-wrapper" style={{width: "24rem"}}>
                                                    <img
                                                        style={{width: '22rem', height: "22rem", objectFit: 'cover'}}
                                                        className="radius-round"
                                                        src={row.img}
                                                        alt="Futár modul"/>
                                                    <div className="circle-image">
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
        </Layout>
    );
};

export default Profile;
