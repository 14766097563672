import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const CustomerNumberDisplay = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
        <Layout>
            {!props.fromHome && <SEO title={"Vevő sorszám kijelzés"}
                                     description={"Nincs szükség, hogy a pultból kiabálj, hogy elkészült a rendelés. Nem kell feltenned értelmetlen kérdéseket. Gyorsétterem, vagy egy \"Üveg Tigris\" az út szélén egyaránt hasznát veheti a pultnál történő gyors kiszolgálás érdekében. Ez a modul a hozzátartozó hardver vásárlása esetén rendelhető."}/>}
            <BreadcrumbOne
                style={{backgroundImage: `url('https://images.unsplash.com/photo-1666185761277-2fa648cab751?q=80&w=3348&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`}}
                title={"<font style='color: white'>Vevő sorszám kijelzés</font>"}
                rootUrl="/"
                parentUrl={"<font style='color: white'>Termékeink</font>"}
                currentUrl={"<font style='color: white'>Vevő sorszám kijelzés</font>"}
            />
            <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5" style={{flexDirection: "row-reverse"}}>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <SectionTitle
                                    classes="text-start"
                                    slogan="Hangalapú rendelésfelvevő modul"
                                    title="Vevő sorszám kijelzés"
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <p className="description mt--40">
                                        Nincs szükség, hogy a pultból kiabálj, hogy elkészült a rendelés. Nem kell
                                        feltenned értelmetlen kérdéseket. Gyorsétterem, vagy egy "Üveg Tigris" az út
                                        szélén egyaránt hasznát veheti a pultnál történő gyors kiszolgálás érdekében. Ez
                                        a modul a hozzátartozó hardver vásárlása esetén rendelhető.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeIn"
                                    animateOut="fadeInOut"
                                    className="testimonial-left-image"
                                    animateOnce={true}
                                    style={{margin: '5rem 0'}}
                                >
                                    <div className="thumbnail">
                                        <div className="circle-image-wrapper" style={{width: "37rem"}}>
                                            <img
                                                style={{width: '35rem', height: "35rem", objectFit: 'cover'}}
                                                className="radius-round"
                                                src="https://images.unsplash.com/photo-1529003600303-bd51f39627fb?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                alt="Futár modul"/>
                                            <div className="circle-image">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Kiabálás mentes
                                            </h6>
                                            <p className="description">
                                                A vevő hívásátvevőhívást a modul megoldja helyetted.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Vendégadatok automatikusan
                                            </h6>
                                            <p className="description">
                                                Nincs szükség felesleges kérdezősködésre.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Gyors kiszolgálás
                                            </h6>
                                            <p className="description">
                                                Hatékonyabb munkafolyamat a pultnál.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Ideális gyorséttermeknek és helyi vendéglátóhelyeknek
                                            </h6>
                                            <p className="description">
                                                Tökéletes a gyors és pontos rendelés
                                                kiadáshozrendeléskiadáshozfelvételhez.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
        </Layout>
    );
};

export default CustomerNumberDisplay;
