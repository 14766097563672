import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const TableMapModule = (props) => {
  const isHomePage =
    window.location.pathname === "/" ||
    window.location.pathname === "/termekeink/szoftver";
  return (
    <Layout>
      {!props.fromHome && (
        <SEO
          title={"Asztaltérkép modul"}
        />
      )}
      <BreadcrumbOne
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1544148103-0773bf10d330?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
        }}
        title={"<font style='color: white'>Asztaltérkép modul</font>"}
        rootUrl="/"
        parentUrl={"<font style='color: white'>Termékeink</font>"}
        currentUrl={"<font style='color: white'>Asztaltérkép modul</font>"}
      />
      <div className="edu-about-area about-style-3 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                <SectionTitle
                  classes="text-start"
                  title="Asztaltérkép modul"
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description mt--40">
                    Éttermi asztalok elhelyezése/szerkesztése asztal
                    térképenasztaltérképen a könnyebb tájékozódás végett.
                    Rendelések rögzítése, ételek leküldése a konyhára, italok
                    beküldése a pultba. Vendégek fizettetésetetése készpénzzel,
                    bankkártyával és kérés alapján vendégenként bontottan is.
                  </p>
                </ScrollAnimation>
              </div>
            </div>

            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                
              <ScrollAnimation
                  animateIn="fadeIn"
                  animateOut="fadeInOut"
                  className="testimonial-left-image"
                  animateOnce={true}
                  style={{ margin: "5rem 0" }}
                >
                  <div className="thumbnail">
                    <div
                      className="circle-image-wrapper"
                      style={{ width: "37rem" }}
                    >
                      <img
                        style={{
                          width: "35rem",
                          height: "35rem",
                          objectFit: "cover",
                        }}
                        className="radius-round"
                        src="https://images.unsplash.com/photo-1567019778516-d1db44e32904?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="Futár modul"
                      />
                      <div className="circle-image">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
      {!isHomePage && <FooterOne />}
    </Layout>
  );
};

export default TableMapModule;
