import React from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/sign-up.json'
import * as smsAuthWaiting from '../../assets/animations/sms-auth.json'
import SignUpForm from "../../components/contact/SignUpForm";
import * as signupInstructionsSent from "../../assets/animations/Rander.json";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Link } from "react-scroll";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { priceFormatter } from "../../utils";
import Checkbox from "@mui/material/Checkbox";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useHappyContext } from "../../context/happy_context.js";
import InputMask from "react-input-mask";

const stripeKey =
  "pk_live_51Ls99BEbdru8ejGZg8GBprE8ODtJ55tLkypJtajjACMUdqi6IKNbCh82j6t4notm1wxRVCvjYJJX54o2FfeWYBHc00j8h5gYsf";
//const stripeKey = "pk_test_51Ls99BEbdru8ejGZpShMHL0IeeOBzWIlIDXoXMaceCYo2I62oYWtiCop3ikwW0pq63F38dB9biu1exrYhHfrsaUF00Qfmsvp2A"

const stripePromise = loadStripe(stripeKey);

const stripe = await stripePromise;

const elements = stripe.elements();

const card = elements.create("card", {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#666EE8",
      color: "#31325F",
      lineHeight: "40px",
      fontWeight: 300,
      fontFamily: "Helvetica Neue",
      fontSize: "15px",

      "::placeholder": {
        color: "#435162",
      },
    },
  },
});
const SummaryModal = ({
  showSummaryModal,
  setShowSummaryModal,
  packageData,
  softwareQuantity,
  selectedMonths,
  devices,
  showRegisterModal,
  setShowRegisterModal,
  setShowPackages,
  showPackages,
  isSendOfferEmail,
  setIsSendOfferEmail,
  vatKey,
  stripeCanMount,
  setStripeCanMount,
  isPackageClicked,
  setIsPackageClicked,
}) => {
  const { isLoggedIn } = useHappyContext();
  const [stripeToken, setStripeToken] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState(0);
  const installationPrice = 10000;

  const [isPaying, setIsPaying] = React.useState(false);
  const [sameAddress, setSameAddress] = React.useState(false);

  const [cardMounted, setCardMounted] = React.useState(false);
  const [registeredData, setRegisteredData] = React.useState(null);

  const [code, setCode] = React.useState('');
  const [processingSignUp, setProcessingSignUp] = React.useState(false);

  const mountCard = () => {
    if (!cardMounted) {
      card.mount("#card-element");
      setCardMounted((prevState) => {
        return true;
      });
    }
  };

  const setOutcome = (result) => {
    const successElement = document.querySelector(".success");
    const errorElement = document.querySelector(".error");
    successElement.classList.remove("visible");
    errorElement.classList.remove("visible");

    if (result.token) {
      // Use the token to create a charge or a customer
      // https://stripe.com/docs/charges
      successElement.querySelector(".token").textContent = result.token.id;
      successElement.classList.add("visible");
      setStripeToken(result.token);
    } else if (result.error) {
      errorElement.textContent = result.error.message;
      errorElement.classList.add("visible");
    }
  };

  card.on("change", (event) => {
    setOutcome(event);
  });

  React.useEffect(() => {
    //if (stripeCanMount && !cardMounted) mountCard()
  }, [stripeCanMount]);

  const getPrice = () => {
    const price = packageData.price;
    if (selectedMonths < 3 || !selectedMonths) return price * softwareQuantity;
    if (selectedMonths >= 3 && selectedMonths < 6)
      return (price - price * 0.03) * softwareQuantity;
    if (selectedMonths >= 6 && selectedMonths < 12)
      return (price - price * 0.06) * softwareQuantity;
    if (selectedMonths === 12) return (price - price * 0.1) * softwareQuantity;
  };
  const getDiscount = () => {
    if (selectedMonths < 3) return 0;
    if (selectedMonths >= 3 && selectedMonths < 6) return 3;
    if (selectedMonths >= 6 && selectedMonths < 12) return 6;
    if (selectedMonths === 12) return 10;
  };

  const getPriceSum = () => {
    //const packagePrice = getPrice()
    const devicesPrice = devices.reduce(
      (accumulator, device) => accumulator + device.price * device.quantity,
      0
    );

    return /*packagePrice + */ devicesPrice;
  };
  const validatePhone = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${window.api}/restaurant/signup/validate/sms`,
      headers: { "Content-Type": "application/json" },
      data: {
        user: registeredData.user._id,
        phone: registeredData.user.phoneNumbers[0].phone,
        code: code,
      },
    })
      .then((res) => {
        if (res.data.validated === "ok") {
          setCurrentStep(3);
        }
      })
      .catch((error) => {
        setProcessingSignUp(false);
        alert(
          "A megadott kódot rendszerünk nem tudta elfogadni! Kérlek próbálj újat kérni."
        );
      });
  };
  const resendSMS = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${window.api}/restaurant/signup/resend/sms`,
      headers: { "Content-Type": "application/json" },
      data: {
        user: registeredData.user._id,
        phone: registeredData.user.phoneNumbers[0].phone,
      },
    })
      .then((res) => {
        setCode("");
        alert("Az SMS-t újraküldtük!");
      })
      .catch((error) => {
        setProcessingSignUp(false);
        alert(
          "Nem sikerült az új kód igénylêse, kérjük keressen minket ügyfélszolgálati vonalainkon egyiké!"
        );
      });
  };
  const processOrder = (stripeToken) => {
    return alert(stripeToken);
  };
  return (
    <Modal
      centered
      show={
        showSummaryModal ||
        showRegisterModal ||
        (isSendOfferEmail && !isLoggedIn)
      }
      onHide={() => {
        Swal.fire({
          title: "Biztos megszakítja a folyamatot?",
          confirmButtonColor: "#231f40",
          confirmButtonText: "Igen",
          showDenyButton: true,
          denyButtonColor: "#f1b44c",
          denyButtonText: "Mégsem",
        }).then((res) => {
          if (res.isConfirmed) {
            card.unmount("#card-element");
            setTimeout(() => {
              setCurrentStep(0);
            }, 250);
            setCardMounted(false);
            setShowSummaryModal(false);
            setShowRegisterModal(false);
            setIsSendOfferEmail(false);
            setStripeCanMount(false);
            setIsPackageClicked(false);
          }
        });
      }}
      className={"order-summary-modal"}
    >
      <Modal.Body style={{ display: "flex" }}>
        {currentStep === 0 && !showSummaryModal && !isLoggedIn && (
          <div
            className="eduvibe-contact-us edu-contact-us-area bg-color-white"
            style={{ padding: "2rem" }}
          >
            <div
              className="container eduvibe-animated-shape"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="title">Regisztrálja vállalkozását</h3>
                    <span className="pre-title">
                      és kezdje meg a munkát azonnal!
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-5 mt--20">
                <div className="col-lg-6">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid cover",
                      },
                    }}
                    width={"485px"}
                    height={"485px"}
                  />
                </div>
                <div className="col-lg-6">
                  <SignUpForm
                    setRegisteredData={setRegisteredData}
                    packageData={packageData}
                    showRegisterModal={showRegisterModal}
                    setCurrentStep={setCurrentStep}
                    setShowPackages={(value) => setShowPackages(value)}
                    setShowRegisterModal={(value) =>
                      setShowRegisterModal(value)
                    }
                    setShowSummaryModal={(value) => setShowSummaryModal(value)}
                    isSendOfferEmail={isSendOfferEmail}
                    setIsSendOfferEmail={(value) => setIsSendOfferEmail(value)}
                    devices={devices}
                    isPackageClicked={isPackageClicked}
                    setIsPackageClicked={(value) => setIsPackageClicked(value)}
                    formStyle="rnt-contact-form rwt-dynamic-form row"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {(currentStep === 1.5 || currentStep === 0) &&
          showSummaryModal &&
          !isSendOfferEmail && (
            <div
              className="eduvibe-contact-us edu-contact-us-area bg-color-white"
              style={{
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                maxWidth: "100%",
                width: "100%",
              }}
            >
              <div className="container eduvibe-animated-shape">
                <div className="row g-5">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                      <h3 className="title">Összesítés</h3>
                      <span className="pre-title">rendelés részletei</span>
                    </div>
                  </div>
                </div>
                {false && (
                  <div className="container g-5 pb-5">
                    <div className="row g-5">
                      <div className="col-12">
                        <h6 className={"m-0"}>
                          {packageData.packageName} csomag
                        </h6>
                      </div>
                    </div>
                    <div className="row g-5">
                      <div className="col-12">
                        <Table aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Mennyiség</TableCell>
                              <TableCell align="right">Egységár</TableCell>
                              <TableCell align="right">Áfa</TableCell>
                              <TableCell align="right">Nettó ár</TableCell>
                              <TableCell align="right">Áfaérték</TableCell>
                              <TableCell align="right">
                                Érték (bruttó)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {softwareQuantity}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(getPrice() / softwareQuantity)}
                              </TableCell>
                              <TableCell align="right">0%</TableCell>
                              <TableCell align="right">
                                {priceFormatter(getPrice())}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(
                                  getPrice() * vatKey - getPrice()
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(getPrice() * vatKey)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                    <div className="row g-5">
                      <div className="col-6">
                        <small>
                          Terminálonkénti szoftver díj + készenléti support díj
                        </small>
                      </div>
                      {getDiscount() > 0 && (
                        <div
                          className="col-6"
                          style={{
                            textAlign: "right",
                            color: "#fff",
                            backgroundColor: "rgba(46,189,7,0.82)",
                            borderRadius: "2px",
                          }}
                        >
                          {getDiscount()}% kedvezmény
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {false && (
                  <div className="container g-5 pb-5">
                    <div className="row g-5">
                      <div className="col-12">
                        <strong>Telepítési alapdíj</strong>
                      </div>
                    </div>
                    <div className="row g-5">
                      <div className="col-12">
                        <Table aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Mennyiség</TableCell>
                              <TableCell align="right">Egységár</TableCell>
                              <TableCell align="right">Áfa</TableCell>
                              <TableCell align="right">Nettó ár</TableCell>
                              <TableCell align="right">Áfaérték</TableCell>
                              <TableCell align="right">Bruttó ár</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {softwareQuantity}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(installationPrice)}
                              </TableCell>
                              <TableCell align="right">27%</TableCell>
                              <TableCell align="right">
                                {priceFormatter(
                                  installationPrice * softwareQuantity
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(
                                  installationPrice *
                                    softwareQuantity *
                                    vatKey -
                                    installationPrice * softwareQuantity
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {priceFormatter(
                                  installationPrice * softwareQuantity * vatKey
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                )}
                {devices.filter((device) => device.quantity > 0).length > 0 && (
                  <div className="container g-5 pb-5">
                    <div className="row g-5">
                      <div className="col-12">
                        <strong>Eszközök</strong>
                      </div>
                    </div>
                    {devices.map((device, id) => {
                      if (device.quantity !== 0)
                        return (
                          <React.Fragment key={id}>
                            <div className="row g-5">
                              <div className="col-12">
                                <span>{device.name}</span>
                              </div>
                            </div>
                            <div className="row g-5">
                              <div className="col-12">
                                <Table aria-label="a dense table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Mennyiség</TableCell>
                                      <TableCell align="right">
                                        Egységár
                                      </TableCell>
                                      <TableCell align="right">Áfa</TableCell>
                                      <TableCell align="right">
                                        Nettó ár
                                      </TableCell>
                                      <TableCell align="right">
                                        Áfaérték
                                      </TableCell>
                                      <TableCell align="right">
                                        Érték (bruttó)
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {device.quantity}
                                      </TableCell>
                                      <TableCell align="right">
                                        {priceFormatter(device.price)}
                                      </TableCell>
                                      <TableCell align="right">27%</TableCell>
                                      <TableCell align="right">
                                        {priceFormatter(
                                          device.price * device.quantity
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        {priceFormatter(
                                          device.price *
                                            device.quantity *
                                            vatKey -
                                            device.price * device.quantity
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        {priceFormatter(
                                          device.price *
                                            device.quantity *
                                            vatKey
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                    })}
                  </div>
                )}
                <div className="container g-5 pb-5">
                  <div className="row g-5">
                    <div className="col-12">
                      <h5 className={"m-0"}>Összesen</h5>
                    </div>
                  </div>
                  <div className="row g-5">
                    <div className="col-12">
                      <Table aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ visibility: "hidden" }}>
                              Mennyiség
                            </TableCell>
                            <TableCell
                              style={{ visibility: "hidden" }}
                              align="right"
                            >
                              Egységár
                            </TableCell>
                            <TableCell
                              style={{ visibility: "hidden" }}
                              align="right"
                            >
                              Áfa
                            </TableCell>
                            <TableCell align="right">Nettó ár</TableCell>
                            <TableCell align="right">Áfaérték</TableCell>
                            <TableCell align="right">Érték (bruttó)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">
                              <strong>{priceFormatter(getPriceSum())}</strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>
                                {priceFormatter(
                                  getPriceSum() * vatKey - getPriceSum()
                                )}
                              </strong>
                            </TableCell>
                            <TableCell align="right">
                              <strong>
                                {priceFormatter(getPriceSum() * vatKey)}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="row g-5">
                  <button
                    className="rn-btn edu-btn"
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => {
                      setCurrentStep(1);
                      setTimeout(() => {
                        mountCard();
                      }, 300);
                    }}
                  >
                    <span>Tovább</span>
                  </button>
                </div>
                <div className="row g-5 mt--20">
                  <div className="col-lg-6" onClick={() => {}}></div>
                </div>
              </div>
            </div>
          )}
        {currentStep === 1 && !isSendOfferEmail && (
          <div
            className="eduvibe-contact-us edu-contact-us-area bg-color-white"
            style={{ padding: "2rem", maxWidth: "100%", width: "100%" }}
          >
            <div className="container eduvibe-animated-shape">
              <div className="row g-5">
                <div className="col-lg-12">
                  <form
                    className="stripe-form"
                    id="credit-card-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsPaying((prevState) => {
                        return true;
                      });
                      const form = document.querySelector("#credit-card-form");
                      const extraDetails = {
                        name: form.querySelector("input[name=cardholder-name]")
                          .value,
                        email: form.querySelector("input[name=email]").value,
                        address: !sameAddress
                          ? form.querySelector("input[name=address]").value
                          : "",
                      };

                      stripe
                        .createToken(card, extraDetails)
                        .then((token) => {
                          if (token.error) {
                            return Swal.fire({
                              icon: "error",
                              title: `Fizetesi hiba`,
                              text: "A választott fizetési módot elutasították, probálj újat!",
                              confirmButtonText: "Rendben",
                            });
                          }
                          return axios({
                            method: "put",
                            url: `${window.api}/company/update/${extraDetails.email}`,
                            headers: { "Content-Type": "application/json" },
                            data: {
                              email: extraDetails.email,
                              devices:
                                devices.filter(
                                  (device) => device.quantity > 0
                                ) || [],
                              sumPrice: getPriceSum() * vatKey,
                              creditCard: token.token.id,
                              address: extraDetails.address || "",
                              name: extraDetails.name,
                            },
                          }).then((res) => {
                            if (res.data.isPayed)
                              Swal.fire({
                                title: "Sikeres fizetés!",
                                html: "Emailben elküldtük a rendelés részleteit.",
                                confirmButtonColor: "#f1b44c",
                              }).then((res) => {
                                if (res.isConfirmed) {
                                  setTimeout(() => {
                                    setCurrentStep(0);
                                  }, 250);
                                  setShowSummaryModal(false);
                                  setShowRegisterModal(false);
                                }
                              });
                          });
                          setCurrentStep(2);
                          return processOrder(token.token.id);
                        })
                        .catch((err) => {
                          console.log("err", err);
                          if (
                            err &&
                            err.response &&
                            err.response.data &&
                            err.response.data.message === "Company not found"
                          ) {
                            Swal.fire({
                              title: "Ön még nem regisztrált rendszerünkhöz!",
                              html: "Ellenőrizze a beírt email címet.",
                              confirmButtonColor: "#f1b44c",
                            });
                          } else {
                            Swal.fire({
                              title: "Hiba a fizetés során!",
                              html: "Ellenőrizze a beírt adatokat.",
                              confirmButtonColor: "#f1b44c",
                            });
                          }
                          setIsPaying((prevState) => {
                            return false;
                          });
                        });
                    }}
                  >
                    <div className="group">
                      <label className="m-0">Kártyán szereplő név</label>
                      <input
                        name="cardholder-name"
                        className="field owner"
                        placeholder="Vezetéknév keresztnév"
                      />
                    </div>
                    <div className="group">
                      <label className="m-0">Email</label>
                      <small>
                        Ha már regisztrálta éttermét, adja meg azt az email
                        címet.
                      </small>
                      <input
                        name="email"
                        className="field owner"
                        placeholder="Email cím"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Checkbox
                        checked={sameAddress}
                        onChange={(e) =>
                          setSameAddress((prevState) => {
                            return !prevState;
                          })
                        }
                      />
                      <p className={"m-0"}>
                        Szállítási cím egyezik regisztrált éttermem számlázási
                        címével?
                      </p>
                    </div>
                    {!sameAddress && (
                      <div className="group">
                        <label className="m-0">Szállítási cím</label>
                        <input
                          name="address"
                          className="field owner"
                          placeholder="Szállítási cím"
                        />
                      </div>
                    )}
                    <div className="group">
                      <label className="m-0">Kártyaszám</label>
                      <div id="card-element" className="field"></div>
                    </div>
                    <button
                      type="submit"
                      disabled={isPaying}
                      className={"rn-btn edu-btn"}
                    >
                      {!isPaying ? "Fizetés" : "Fizetés folyamatban..."}
                    </button>
                    <div className="outcome">
                      <div className="error"></div>
                      <div className="success">
                        <span className="token"></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div
            className="eduvibe-contact-us edu-contact-us-area bg-color-white "
            style={{ padding: '2rem 1rem' }}
          >
            <div className="container eduvibe-animated-shape">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="title">
                      Elküldtünk Önnek egy szöveges üzenetet
                    </h3>
                    <span className="pre-title">
                      kérjük adja meg az SMS-ben található 6 jegyű kódot:
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-5 mt--20">
                <div className="col-lg-6">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: smsAuthWaiting,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid contain",
                      },
                    }}
                    width={"485px"}
                    height={"485px"}
                  />
                </div>
                <div className="col-lg-6">
                  <form
                    className={"rnt-contact-form rwt-dynamic-form row"}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validatePhone(e);
                    }}
                    id={"signup-form"}
                  >
                    <div className="col-lg-12">
                      <p>
                        A hitelesítés célja csupán, hogy megbizonyosodjunk róla,
                        hogy Ön valóban egy személy és a késóbbiek során
                        elérhessük, valamint beazonosíthassuk vele
                        szolgáltatásainkban*
                      </p>
                      <small>
                        *További részleteket az Adatvédelmi oldalunkon talál
                      </small>
                      <div className="form-group">
                        <InputMask
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          mask="999-999"
                          style={{
                            fontSize: "4rem",
                            textAlign: "center",
                          }}
                          autoFocus
                          onClick={() => setCode("")}
                          maskChar={"X"}
                          className="form-control form-control-lg"
                          name="restaurantName"
                          placeholder="SMS-ben kapott XXX-XXX kód"
                          required
                        />
                      </div>
                    </div>

                    <button className="rn-btn edu-btn w-100" type="submit">
                      <span>Telefonszám megerősítése</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>

                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <a
                        style={{ alignItems: "flex-end" }}
                        className={"text-primary pull-right float-right"}
                        href={"#"}
                        onClick={resendSMS}
                      >
                        Kód újraküldése
                      </a>
                    </div>
                  </form>
                </div>
              </div>

              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-2">
                  <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-3">
                  <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div
            className="eduvibe-contact-us edu-contact-us-area bg-color-white "
            style={{ padding: "2rem" }}
          >
            <div className="container eduvibe-animated-shape">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="title">Már majdnem készen vagyunk!</h3>
                    <span className="pre-title">
                      A profilját sikeresen létrehoztuk.
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-5 mt--20">
                <div className="col-lg-4">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: signupInstructionsSent,
                      rendererSettings: {
                        preserveAspectRatio: "xMdYMid covers",
                      },
                    }}
                    width={"285px"}
                    height={"285px"}
                  />
                </div>
                <div className="col-lg-8">
                  <form
                    className={"rnt-contact-form rwt-dynamic-form row"}
                    id={"signup-form"}
                  >
                    <div className="col-lg-12">
                      <div className="about-feature-list">
                        <div
                          className="our-feature sal-animate"
                          data-sal-delay="150"
                          data-sal="slide-up"
                          data-sal-duration="800"
                        >
                          <div
                            className="icon"
                            style={{ backgroundColor: "lightgreen" }}
                          >
                            <CheckIcon fontSize={"large"} />
                          </div>
                          <div className="feature-content">
                            <h6 className="feature-title">
                              Vendéglátó helyét létrehoztuk
                            </h6>
                            <p className="feature-description">
                              A beállításokat a partnerfelületen tudja
                              befejezni, ezután kerül aktiválásra teljesen, de
                              közben a munkát már megkezdheti
                            </p>
                          </div>
                        </div>

                        <div
                          className="our-feature sal-animate"
                          data-sal-delay="150"
                          data-sal="slide-up"
                          data-sal-duration="800"
                        >
                          <div
                            className="icon"
                            style={{
                              backgroundColor: "#F7CC45",
                              color: "white",
                            }}
                          >
                            <HourglassTopIcon
                              color={"inherit"}
                              fontSize={"large"}
                              f
                            />
                          </div>
                          <div className="feature-content">
                            <h6 className="feature-title">
                              Elkészült személyes profilja
                            </h6>
                            <p className="feature-description">
                              Ehhez már csak a megaddott e-mail cím
                              helyességéröl kell meggyőződnünk, melyhez
                              elküldtük az aktiváláshoz és kezdéshez szüksêges
                              részleteket.
                            </p>
                            <p className="feature-description">
                              Kérjük kövesse az e-mailben található
                              instrukciókat, melyben részletesen megmutatjuk
                              szolgáltatásunk használatát.
                            </p>
                            <p className="feature-description">
                              Amennyiben további kérdése van{" "}
                              <Link to={"/kapcsolat/ugyfelszolgalat"}>
                                Vegye fel velünk a kapcsolatot
                              </Link>
                            </p>
                          </div>
                        </div>
                        {false && (
                          <div
                            className="our-feature sal-animate"
                            data-sal-delay="150"
                            data-sal="slide-up"
                            data-sal-duration="800"
                          >
                            <div
                              className="icon"
                              style={{
                                backgroundColor: "#eeeeee",
                                color: "black",
                              }}
                            >
                              <CloudDownloadIcon
                                color={"inherit"}
                                fontSize={"large"}
                              />
                            </div>
                            <div className="feature-content">
                              <h6 className="feature-title">
                                Programok letöltése és telepítése
                              </h6>
                              <p className="feature-description">
                                Kattintson a letöltés gombra, majd kövesse az
                                utasításokat
                              </p>
                              <br />
                              <button
                                className="rn-btn edu-btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <span>Letötés</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-2">
                  <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-3">
                  <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SummaryModal;
