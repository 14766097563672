import React, {useState} from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactUsForm from '../../components/contact/ContactUsForm';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/sign-up.json'
import * as smsAuthWaiting from '../../assets/animations/sms-auth.json'
import * as signupInstructionsSent from '../../assets/animations/Rander.json'
import SignUpForm from "../../components/contact/SignUpForm";
import InputMask from "react-input-mask";
import serialize from "form-serialize";

import CheckIcon from '@mui/icons-material/Check';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import axios from "axios";
import {Link} from "react-scroll";
import FooterOne from "../../common/footer/FooterOne";

let smsTimeOut;

const RegisterService = ({modalMode}) => {
    const [currentStep, setCurrentStep] = React.useState(0)
    const [registeredData, setRegisteredData] = useState(null);
    const [code, setCode] = useState('');
    const [processingSignUp, setProcessingSignUp] = useState(false);

    const validatePhone = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${window.api}/restaurant/signup/validate/sms`,
            headers: {'Content-Type': 'application/json'},
            data: {
                user: registeredData.user._id,
                phone: registeredData.user.phoneNumbers[0].phone,
                code: code
            }
        })
            .then(res => {
                if (res.data.validated === 'ok') {
                    setCurrentStep(3);
                }
            })
            .catch(error => {
                setProcessingSignUp(false);
                alert('A megadott kódot rendszerünk nem tudta elfogadni! Kérlek próbálj újat kérni.')
            })
    };
    const resendSMS = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${window.api}/restaurant/signup/resend/sms`,
            headers: {'Content-Type': 'application/json'},
            data: {
                user: registeredData.user._id,
                phone: registeredData.user.phoneNumbers[0].phone
            }
        })
            .then(res => {
                setCode('');
                alert('Az SMS-t újraküldtük!')
            })
            .catch(error => {
                setProcessingSignUp(false);
                alert('Nem sikerült az új kód igénylêse, kérjük keressen minket ügyfélszolgálati vonalainkon egyiké!')
            })
    };
    const getTitle = () => {
        if(currentStep === 3) return "Regisztráció végleges"
        if(currentStep === 2) return "Adatok véglegesítése"
        if(currentStep === 1) return "Telefonszám hitelesítése"
        if(currentStep === 0) return "Partner regisztráció"
    }
    const getDescription = () => {
        if(currentStep === 3) return "Köszöntük ügyfeleink között!"
        if(currentStep === 2) return "Köszöntük ügyfeleink között! Ha minden stimmel, e-mailben elküldtünk minden hozzáférést!"
        if(currentStep === 1) return "Kérjük adja meg az SMS-ben kapott kódot"
        if(currentStep === 0) return "Alapvető információk megadása"
    }

    return (
        <>
            <SEO title={getTitle()} description={getDescription()}/>
            <Layout>
                {currentStep === 3 &&
                    <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white "
                         style={{minHeight: 750}}>
                        <div className="container eduvibe-animated-shape">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="title">Már majdnem készen vagyunk!</h3>
                                        <span className="pre-title">A profilját sikeresen létrehoztuk.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-5 mt--20">
                                <div className="col-lg-4">
                                    <Lottie options={{
                                        loop: false,
                                        autoplay: true,
                                        animationData: signupInstructionsSent,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMdYMid covers'
                                        }
                                    }} width={"285px"} height={"285px"}/>
                                </div>
                                <div className="col-lg-8">
                                    <form className={'rnt-contact-form rwt-dynamic-form row'} onSubmit={(e) => {
                                        e.preventDefault();
                                        validatePhone(e)
                                    }} id={'signup-form'}>
                                        <div className="col-lg-12">
                                            <div className="about-feature-list">

                                                <div className="our-feature sal-animate" data-sal-delay="150"
                                                     data-sal="slide-up" data-sal-duration="800">
                                                    <div className="icon" style={{backgroundColor: 'lightgreen'}}>
                                                        <CheckIcon fontSize={'large'}/>
                                                    </div>
                                                    <div className="feature-content">
                                                        <h6 className="feature-title">Vendéglátó helyét létrehoztuk</h6>
                                                        <p className="feature-description">
                                                            A beállításokat a partnerfelületen tudja befejezni, ezután
                                                            kerül aktiválásra teljesen, de közben a munkát már
                                                            megkezdheti
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="our-feature sal-animate" data-sal-delay="150"
                                                     data-sal="slide-up" data-sal-duration="800">
                                                    <div className="icon"
                                                         style={{backgroundColor: '#F7CC45', color: 'white'}}>
                                                        <HourglassTopIcon color={'inherit'} fontSize={'large'} f/>
                                                    </div>
                                                    <div className="feature-content">
                                                        <h6 className="feature-title">
                                                            <a style={{float: 'righ', fontSize: '1.5rem'}}
                                                               className={'text-primary pull-right float-right'}
                                                               href={'#'}
                                                               onClick={resendSMS}>E-mail újraküldése</a>

                                                            Elkészült személyes profilja

                                                        </h6>
                                                        <p className="feature-description">
                                                            Ehhez már csak a megaddott e-mail cím helyességéröl kell
                                                            meggyőződnünk, melyhez elküldtük az aktiváláshoz és
                                                            kezdéshez szüksêges
                                                            részleteket.
                                                        </p>
                                                        <p className="feature-description">
                                                            Kérjük kövesse az e-mailben található instrukciókat, melyben
                                                            részletesen megmutatjuk szolgáltatásunk használatát.
                                                        </p>
                                                        <p className="feature-description">
                                                            Amennyiben további kérdése van <Link
                                                            to={'/kapcsolat/ugyfelszolgalat'}>Vegye fel velnk a
                                                            kapcsolatot</Link>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="our-feature sal-animate disabled" style={{opacity: .6}}
                                                     data-sal-delay="150"
                                                     data-sal="slide-up" data-sal-duration="800">
                                                    <div className="icon"
                                                         style={{backgroundColor: '#eeeeee', color: 'black'}}>
                                                        <PendingActionsIcon color={'inherit'} fontSize={'large'}/>
                                                    </div>
                                                    <div className="feature-content">
                                                        <h6 className="feature-title">Díjcsomag kiválasztása és
                                                            szerzödéskötés</h6>
                                                        <p className="feature-description">
                                                            Az Ön számára legideálisabb díjcsomag kiválasztása után
                                                            rendszerünk elökêszíti a digitális szerzödêsét, melyet
                                                            egyszerűen hitelesíthet, ha mindent rendben talált
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="our-feature sal-animate disabled" style={{opacity: .6}}
                                                     data-sal-delay="150"
                                                     data-sal="slide-up" data-sal-duration="800">
                                                    <div className="icon"
                                                         style={{backgroundColor: '#eeeeee', color: 'black'}}>
                                                        <CloudDownloadIcon color={'inherit'} fontSize={'large'}/>
                                                    </div>
                                                    <div className="feature-content">
                                                        <h6 className="feature-title">Programok, eszközök
                                                            aktiválása</h6>
                                                        <p className="feature-description">
                                                            Amenyiben rendelkezik eszköz(ök)kel, tötse le operációs
                                                            rendszeréhez tartozó legfrissebb verziót.<br/>
                                                            Apple és Android esetén ezek megtalálhatóak a bépített
                                                            alkalmazásboltokban, tőlünk vásárolt eszköz esetén ezek
                                                            előretelepítve érkeznek
                                                        </p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-3">
                                    <img src="/images/shapes/shape-15.png" alt="Shape Thumb"/>
                                </div>
                            </div>
                        </div>
                    </div>}


                {currentStep === 2 &&
                    <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white "
                         style={{minHeight: 750}}>
                        <div className="container eduvibe-animated-shape">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="title">Elküldtünk Önnek egy szöveges üzenetet</h3>
                                        <span
                                            className="pre-title">kérjük adja meg az SMS-ben található 6 jegyű kódot:</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-5 mt--20">
                                <div className="col-lg-6">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: smsAuthWaiting,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid contain'
                                        }
                                    }} width={"485px"} height={"485px"}/>
                                </div>
                                <div className="col-lg-6">
                                    <form className={'rnt-contact-form rwt-dynamic-form row'} onSubmit={(e) => {
                                        e.preventDefault();
                                        validatePhone(e)
                                    }} id={'signup-form'}>
                                        <div className="col-lg-12">
                                            <p>
                                                A hitelesítés célja csupán, hogy megbizonyosodjunk róla, hogy Ön valóban
                                                egy személy és a késóbbiek során elérhessük, valamint beazonosíthassuk
                                                vele szolgáltatásainkban*
                                            </p>
                                            <small>*További részleteket az Adatvédelmi oldalunkon talál</small>
                                            <div className="form-group">
                                                <InputMask value={code} onChange={(e) => setCode(e.target.value)}
                                                           mask="999-999" style={{
                                                    fontSize: '4rem',
                                                    textAlign: "center"
                                                }} autoFocus onClick={() => setCode('')} maskChar={'X'}
                                                           className="form-control form-control-lg"
                                                           name="restaurantName"
                                                           placeholder="SMS-ben kapott XXX-XXX kód" required/>
                                            </div>
                                        </div>

                                        <button className="rn-btn edu-btn w-100" type="submit">
                                            <span>Telefonszám megerősítése</span><i
                                            className="icon-arrow-right-line-right"></i>
                                        </button>

                                        <div style={{
                                            marginTop: 30,
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            width: '100%'
                                        }}>
                                            <a style={{alignItems: 'flex-end'}}
                                               className={'text-primary pull-right float-right'} href={'#'}
                                               onClick={resendSMS}>Kód újraküldése</a>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-3">
                                    <img src="/images/shapes/shape-15.png" alt="Shape Thumb"/>
                                </div>
                            </div>
                        </div>
                    </div>}

                {currentStep === 0 &&
                    <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white "
                         style={{minHeight: 750}}>
                        <div className="container eduvibe-animated-shape">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="title">Regisztrálja vállalkozását</h3>
                                        <span className="pre-title">és kezdje meg a munkát azonnal!</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-5 mt--20">
                                <div className="col-lg-6">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid cover'
                                        }
                                    }} width={"485px"} height={"485px"}/>
                                </div>
                                <div className="col-lg-6">
                                    <SignUpForm setRegisteredData={setRegisteredData} setCurrentStep={setCurrentStep}
                                                formStyle="rnt-contact-form rwt-dynamic-form row"/>
                                </div>
                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb"/>
                                </div>
                                <div className="shape-image shape-image-3">
                                    <img src="/images/shapes/shape-15.png" alt="Shape Thumb"/>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div className="edu-contact-map-area edu-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne/>
            </Layout>
        </>
    )
}
export default RegisterService;
