import React, { useState, useEffect } from "react";
import HeaderTwo from "./header/HeaderTwo";

const Layout = ({ children, showHeader = true }) => {
  const isHomePage = window.location.pathname === "/";
  React.useEffect(() => {
    window.gtag("config", "G-9R8J3Z4WG9", {
      page_path: window.location.pathname,
    });
  });
  return (
    <>
      <main className="main-wrapper">
        {(!isHomePage && showHeader) && <HeaderTwo />}
        {children}
      </main>
    </>
  );
};

export default Layout;
