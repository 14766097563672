import React from 'react'
import Layout from '../../common/Layout'
import SEO from "../../common/SEO";
import FooterOne from '../../common/footer/FooterOne';

const AdatkezelesIndex = () => {
    return (
        <Layout>
            <SEO title="Adatkezelési tájékoztató"/>
            <div className="adatkezeles-container container">
                <h4>
                    Adatkezelési tájékoztató Happy Gastro Vendéglátós rendszer használatához
                </h4>
                <ol>
                    <li>
                        <a href="#1">
                            Az adatkezelési tájékoztató célja
                        </a>
                    </li>
                    <li>
                        <a href="#2">
                            Az Adatkezelő adatai
                        </a>
                    </li>
                    <li>
                        <a href="#3">
                            Alapelvek az adatkezelés során
                        </a>
                    </li>
                    <li>
                        <a href="#4">
                            Fogalom meghatározások
                        </a>
                    </li>
                    <li>
                        <a href="#5">
                            Adatkezelő Adatkezelési és a kezelt személyes adatok
                        </a>
                    </li>
                    <li>
                        <a href="#6">
                            Technikai adatok
                        </a>
                    </li>
                    <li>
                        <a href="#7">
                            A Böngésző Süti - Cookie
                        </a>
                    </li>
                    <li>
                        <a href="#8">
                            Az adatkezelés célja, módja és jogalapja
                        </a>
                    </li>
                    <li>
                        <a href="#9">
                            Az adatok tárolása és biztonsága
                        </a>
                    </li>
                    <li>
                        <a href="#10">
                            Érintettek jogai
                        </a>
                    </li>
                    <li>
                        <a href="#11">
                            Egyéb rendelkezések
                        </a>
                    </li>
                </ol>
                <div id="1">
                    <strong>
                        1. Az adatkezelési tájékoztató célja
                    </strong>
                    <p>
                        Jelen dokumentum célja, hogy a Happy Gastro Kft., mint adatkezelő (a
                        továbbiakban egységesen Adatkezelő) leírja az Adatkezelő szervezetében
                        alkalmazott és működő, személyes adatnak minősülő adatokra vonatkozó
                        adatvédelmi szabályokat, eljárásokat és védelmi intézkedéseket.
                        <br/>
                        <br/>
                        Az Adatkezelő egyúttal e dokumentumban tájékoztatja ügyfeleit, partnereit,
                        illetőleg minden olyan természetes- és jogi személyt, akik az Adatkezelővel
                        bármilyen - jogi szempontból értelmezhető - kapcsolatban állnak és a
                        személyes kezelése során érintettségük keletkezik, az általa kezelt személyes
                        adatok kezelésének szabályairól, az alkalmazott védelmi intézkedésekről
                        eljárásokról és az adatkezelés módjáról.
                        <br/>
                        <br/>
                        Az Adatkezelő jelen Adatkezelési tájékoztatóban leírt szabályokat,
                        rendelkezéseket és kötelezettségeket saját magára jogilag kötelező
                        érvényűnek tekinti és működése során alkalmazza, valamint kijelenti, hogy
                        jelen dokumentumban leírt és alkalmazott adatvédelmi szabályok és eljárások
                        megfelelnek a hatályos adatvédelmi nemzeti és Európai Uniós
                        jogszabályoknak. Adatkezelő kijelenti továbbá, hogy fontosnak tekinti az
                        információs önrendelkezés jogát, különös tekintettel a személyes adatokra és
                        saját hatókörében minden elérhető szervezeti, működési, szabályozási és
                        technológia intézkedést megtesz e jogok betartása és betartatása érdekében.
                    </p>
                </div>
                <div id="2">
                    <strong>2. Szolgáltató adatai</strong>
                    <ul>
                        <li>Név: Happy Gastro Kft</li>
                        <li> Székhely: 1034 Budapest, Bécsi út 89-91. fsz.1.</li>
                        <li>Cégjegyzékszám: 01 09 371601</li>
                        <li>Adószám: 28735636-1-41</li>
                        <li>Telefonszám: +36-1-300-91-05</li>
                        <li>Weblap: www.happygastro.hu</li>
                        <li> E-mail: info@happygastro.hu</li>
                    </ul>
                    <div id="2.1">
                        <strong>2.1 Az Adatkezelő elérhetőségei</strong>
                        <p>
                            Adatvédelmi kérdésekben az Adatkezelő az alábbi elérhetőségeken kereshető
                            meg:
                            <br/><br/>
                            Központi telefonszám: +36-1-300-91-05
                            <br/><br/>
                            Az Adatkezelő a hozzá beérkezett adatvédelmi megkereséseket (e-mail) a 3.8.
                            pontban, az erre az adatkezelésre vonatkozó, meghatározott ideig őrzi meg.
                            Ennek leteltével ezek visszavonhatatlan módon törlésre kerülnek.
                        </p>
                    </div>
                    <div id="2.2">
                        <strong>2.2 Adatkezelő Adatvédelmi tisztviselője</strong>
                        <p>
                            Név: Kiss Edina
                            <br/><br/>
                            Telefonszám: +36-1-300-91-05
                            <br/><br/>
                            Email cím: info@happygastro.hu
                        </p>
                    </div>
                </div>
                <div id="3">
                    <strong>3. Alapelvek az adatkezelés során</strong>
                    <p>Az Adatkezelő az adatkezelése során az alábbi alapelveket követi:</p>
                    <ul className={'styled'}>
                        <li>
                            a személyes adatokat jogszerűen és tisztességesen, az Érintettek
                            számára átlátható módon kezeli („jogszerűség, tisztességes eljárás és
                            átláthatóság”),
                        </li>
                        <li>
                            a személyes adatokat csak meghatározott, egyértelmű és jogszerű célból
                            gyűjti és azokat nem kezeli a célokkal össze nem egyeztethető módon
                            („célhoz kötöttség”),
                        </li>
                        <li>
                            az Adatkezelő által gyűjtött és kezelt személyes adatok az adatkezelés
                            céljai szempontjából megfelelőek és relevánsak, valamint csak a
                            szükségesre korlátozódnak („adattakarékosság”),
                        </li>
                        <li>
                            az Adatkezelő minden észszerű intézkedést megtesz annak érdekében,
                            hogy az általa kezelt adatok pontosak és naprakészek legyenek, a
                            pontatlan személyes adatokat haladéktalanul törli vagy helyesbíti
                            („pontosság”),
                        </li>
                        <li>
                            a személyes adatokat olyan formában tárolja, hogy az Érintett csak a
                            személyes adatok kezelése céljainak eléréséhez szükséges ideig legyen
                            azonosítható („korlátozott tárolhatóság”),
                        </li>
                        <li>
                            az Adatkezelő megfelelő technikai és szervezési intézkedések
                            alkalmazásával biztosítja a személyes adatok biztonságát az adatok
                            jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével,
                            megsemmisítésével vagy károsodásával szemben („integritás és
                            bizalmas jelleg”).
                        </li>
                    </ul>
                </div>
                <div id="4">
                    <strong>4. Fogalom meghatározások</strong>
                    <ol>
                        <li>
                            személyes adat”: azonosított vagy azonosítható természetes személyre
                            („érintett”) vonatkozó bármely információ; azonosítható az a természetes
                            személy, aki közvetlen vagy közvetett módon, különösen valamely
                            azonosító, például név, szám, helymeghatározó adat, online azonosító
                            vagy a természetes személy testi, fiziológiai, genetikai, szellemi,
                            gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy
                            több tényező alapján azonosítható;
                        </li>
                        <li>
                            „adatkezelés”: a személyes adatokon vagy adatállományokon
                            automatizált vagy nem automatizált módon végzett bármely művelet
                            vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés,
                            tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés,
                            felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő
                            hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás,
                            korlátozás, törlés, illetve megsemmisítés;
                        </li>
                        <li>
                            „adatkezelő”: az a természetes vagy jogi személy, közhatalmi szerv,
                            ügynökség vagy bármely egyéb szerv, amely a személyes adatok
                            kezelésének céljait és eszközeit önállóan vagy másokkal együtt
                            meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy a
                            tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő
                            kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami
                            jog is meghatározhatja;
                        </li>
                        <li>
                            „adatfeldolgozó”: az a természetes vagy jogi személy, közhatalmi szerv,
                            ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében
                            személyes adatokat kezel;
                        </li>
                        <li>
                            „címzett”: az a természetes vagy jogi személy, közhatalmi szerv,
                            ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a személyes
                            adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon közhatalmi
                            szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a
                            tagállami joggal összhangban férhetnek hozzá személyes adatokhoz,
                            nem minősülnek címzettnek; az említett adatok e közhatalmi szervek
                            általi kezelése meg kell, hogy feleljen az adatkezelés céljainak
                            megfelelően az alkalmazandó adatvédelmi szabályoknak;
                        </li>
                        <li>
                            „az érintett hozzájárulása”: az érintett akaratának önkéntes, konkrét és
                            megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel
                            az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező
                            cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes
                            adatok kezeléséhez;
                        </li>
                        <li>
                            „adatvédelmi incidens”: a biztonság olyan sérülése, amely a továbbított,
                            tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes
                            megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését
                            vagy az azokhoz való jogosulatlan hozzáférést eredményezi.
                        </li>
                    </ol>
                    <p>
                        A személyes adatok kezelésére vonatkozó elvek <br/>
                        A személyes adatok:
                    </p>
                    <ol>
                        <li>
                            kezelését jogszerűen és tisztességesen, valamint az érintett számára
                            átlátható módon kell végezni („jogszerűség, tisztességes eljárás és
                            átláthatóság”);
                        </li>
                        <li>
                            gyűjtése csak meghatározott, egyértelmű és jogszerű célból történjen, és
                            azokat ne kezeljék ezekkel a célokkal össze nem egyeztethető módon; a
                            89. cikk (1) bekezdésének megfelelően nem minősül az eredeti céllal
                            össze nem egyeztethetőnek a közérdekű archiválás céljából, tudományos
                            és történelmi kutatási célból vagy statisztikai célból történő további
                            adatkezelés („célhoz kötöttség”);
                        </li>
                        <li>
                            az adatkezelés céljai szempontjából megfelelőek és relevánsak kell, hogy
                            legyenek, és a szükségesre kell korlátozódniuk („adattakarékosság”);
                        </li>
                        <li>
                            pontosnak és szükség esetén naprakésznek kell lenniük; minden
                            észszerű intézkedést meg kell tenni annak érdekében, hogy az
                            adatkezelés céljai szempontjából pontatlan személyes adatokat
                            haladéktalanul töröljék vagy helyesbítsék („pontosság”);
                        </li>
                        <li>
                            tárolásának olyan formában kell történnie, amely az érintettek
                            azonosítását csak a személyes adatok kezelése céljainak eléréséhez
                            szükséges ideig teszi lehetővé; a személyes adatok ennél hosszabb ideig
                            történő tárolására csak akkor kerülhet sor, amennyiben a személyes
                            adatok kezelésére a 89. cikk (1) bekezdésének megfelelően közérdekű
                            archiválás céljából, tudományos és történelmi kutatási célból vagy
                            statisztikai célból kerül majd sor, az e rendeletben az érintettek jogainak
                            és szabadságainak védelme érdekében előírt megfelelő technikai és
                            szervezési intézkedések végrehajtására is figyelemmel („korlátozott
                            tárolhatóság”);
                        </li>
                        <li>
                            kezelését oly módon kell végezni, hogy megfelelő technikai vagy
                            szervezési intézkedések alkalmazásával biztosítva legyen a személyes
                            adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes
                            kezelésével, véletlen elvesztésével, megsemmisítésével vagy
                            károsodásával szembeni védelmet is ideértve („integritás és bizalmas
                            jelleg”).
                        </li>
                    </ol>
                    <p>
                        Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell lennie e
                        megfelelés igazolására („elszámoltathatóság”).
                        Az adatkezelő nyilatkozik, hogy adatkezelése a jelen pontban foglalt
                        alapelveknek megfelelően történik.
                    </p>
                </div>
                <div id="5">
                    <strong>5. Adatkezelő Adatkezelései és a kezelt személyes adatok</strong>
                    <div id="5.1">
                        <strong>
                            5.1. Regisztráció során kötelezően kitöltendő személyes adatok: Név,
                            telefonszám, e-mail cím.
                        </strong>
                        <p>
                            Az adatkezelés célja: azonosítás, kapcsolattartás, válaszüzenetek küldése. Az
                            adatkezelés jogalapja: hozzájárult regisztráció során hogy fenti adatokat
                            tároljuk és kezeljük.
                        </p>
                    </div>
                    <div id="5.2">
                        <strong>
                            5.2. A hírlevélhez kapcsolódó adatok
                        </strong>
                        <p>
                            A felíratkozóknak hírlevelet küldünk, melyről bármikor leiratkozhatnak. Az
                            adatkezelésben kezelt adatok: Név, email cím.
                            <br/><br/>
                            Az adatkezelés jogalapja: Aki jelentkezik a hírlevélre, hozzájárul, hogy üzenetet
                            küldjünk neki kapcsolattartási célból
                            <br/><br/>
                            Az adatkezelés célja: A felhasználó jogainak védelme.
                            <br/><br/>
                            Beazonosítása és a kapcsolattartás, a kiküldött levelek
                            testreszabása, ügyféltámogatás, tanácsadás, termék bemutatása, kérdések
                            megválaszolása.
                            <br/><br/>
                            Törvényi kötelezettségeinknek történő megfelelés.
                            <br/><br/>
                            Jogos üzleti érdekeink érvényesítése.
                            <br/><br/>
                            Az adatszolgáltatás elmaradásának lehetséges következményei: Amennyiben
                            nem óhajt levelt kapni, nem tudjuk értesíteni az eseményt érintő változásokról.
                            <br/><br/>
                            Az adatkezelésben kezelt adatok megőrzési ideje: Az adatokat a jelentkezéstől
                            a törlés igény idejére őrizzük meg, utána archiválásra, vagy kérésre törlésre
                            kerülnek.
                        </p>
                    </div>
                    <div id="5.3">
                        <strong>
                            5.3. Számlázással kapcsolatos adatkezelés
                        </strong>
                        <p>
                            Számláinkat elsősorban elektronikusan állítjuk ki. A kiállításhoz a következő
                            adatokat kérjük el:számlázási név, számlázási cím, adószám, e-mail cím.
                            Számláinkat átutalással kérjük kiegyenlíteni, a bankszámlakivonat tartalmazza
                            a nevet és a bankszámlaszámot. Jogi kötelezettségünk a számlázás, így
                            szükséges ezen adatok bekérése.
                            <br/><br/>
                            A tárolás időtartama nem a mi döntésünk, jogszabály írja elő, hogy a kiállítás
                            évében, plusz még nyolc évig meg kell őriznünk.
                            <br/><br/>
                            Amennyiben nem adja meg Felhasználó az adatait úgy nem tudjuk teljesíteni
                            számlázási kötelezettségünket, ezért lehetetlen a kért szolgáltatást
                            nyújtanunk.
                        </p>
                    </div>
                </div>
                <div id="6">
                    <strong>
                        6. Technikai adatok
                    </strong>
                    <p>
                        Technikai adatok, azon adatok, amelyek az Adatkezelő rendszereinek
                        működése során többnyire automatikusan keletkeznek és kerülnek rögzítésre.
                        Egyes technikai adatokat a rendszer, az Érintett külön nyilatkozata, vagy
                        cselekménye nélkül is tárol és bizonyos esetekben automatikusan naplóz. A
                        technikai adatok az Érintett személyének azonosítására közvetlenül nem
                        alkalmasak, azonban összekapcsolhatók felhasználói adatokkal, így az
                        azonosítás elvileg lehetővé válik. Ilyen adatkapcsolatokat az Adatkezelő nem
                        készít, kivéve egyes olyan esetekben, amikor erre Adatkezelőt törvény kötelezi.
                        A technikai adatokhoz kizárólag az Adatkezelő és Adatfeldolgozói férhetnek
                        hozzá.
                    </p>
                </div>
                <div id="7">
                    <strong>7. Böngésző Süti - Cookie</strong>
                    <div id="7.1">
                        <strong>7.1 A sütik feladata</strong>
                        <p>
                            A HTTP-süti (süti, cookie) kisméretű adatcsomag, amelyet az internetes
                            böngészés során a látogatott weboldalt tartalmazó szerver hozza létre a kliens
                            webböngészője segítségével, az első látogatás alkalmával, ha ez a
                            böngészőben engedélyezve van. A sütik a felhasználó számítógépén, előre
                            meghatározott, böngésző típusonként eltérő helyen[a1] tárolódnak. A további
                            látogatások során a tárolt sütit a böngésző visszaküldi a webszervernek,
                            különféle, a kliensről szóló információkkal együtt. A sütik segítségével a
                            szervernek lehetősége van az adott felhasználó azonosítására, róla különféle
                            információk gyűjtésére és ezekből elemzések készítésére. A sütik főbb funkciói:
                            <br/><br/>
                            - információkat gyűjtenek a látogatókról és eszközeikről;
                            <br/><br/>
                            - megjegyzik a látogatók egyéni beállításait, amelyek felhasználásra
                            kerül(het)nek pl. online tranzakciók igénybevételekor, így nem kell újra
                            begépelni őket;
                            <br/><br/>
                            - megkönnyítik, egyszerűsítik, kényelmesebbé, gördülékenyebbé teszik az
                            adott weboldal használatát;
                            <br/><br/>
                            - szükségtelenné teszik már megadott adatok újramegadását;
                            <br/><br/>
                            - általában javítanak a felhasználói élményen.
                            <br/><br/>
                            A sütik használatával az Adatkezelő adatkezelést végez, amelynek fő céljai:
                            <br/><br/>
                            felhasználó azonosítás, az egyes munkamenetek azonosítása,hozzáférésre
                            használt eszközök azonosítása, bizonyos megadott adatok tárolása,nyomon
                            követési és helyinformációk tárolása és továbbítása, analitikai mérésekhez
                            szükséges adatok tárolása és továbbítása.
                        </p>
                    </div>
                    <div id="7.2">
                        <strong>7.2 Munkamenet sütik - session cookie</strong>
                        <p>
                            Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen
                            böngészhessék az online-erp.hu weboldalt, használhassák annak funkcióit, és
                            az ott elérhető szolgáltatásokat. Az ilyen típusú sütik érvényességi ideje a
                            munkamenet (böngészés) befejezéséig tart, a böngésző bezárásával a sütik e
                            fajtája automatikusan törlődik a számítógépről, illetve a böngészésre használt
                            más eszközről.
                        </p>
                    </div>
                    <div id="7.3">
                        <strong>7.3 Harmadik fél által elhelyezett analitikai sütik - analitics cookie</strong>
                        <p>
                            Az Adatkezelő weboldalán alkalmazza a Google Analytics mint harmadik fél
                            sütijeit is. A Google Analytics statisztikai célú szolgáltatás használatával az
                            Adatkezelő szervere információkat gyűjt azzal kapcsolatban, hogy a látogatók
                            hogyan használják a weboldalt. Az adatot a honlap fejlesztésének és a
                            felhasználói élmény javításának céljával használja fel. Ezen sütik szintén
                            lejáratukig a látogató számítógépén vagy böngészésre használt más eszközén,
                            annak böngészőjében maradnak, illetve amíg a látogató nem törli őket.
                        </p>
                    </div>
                    <div id="7.4">
                        <strong>7.4 Sütik letiltásának-, sütikkel kapcsolatos szabályok beállításának
                            lehetősége</strong>
                        <p>
                            Az Érintettnek lehetősége van bizonyos típusú sütikre vonatkozó szabályok
                            beállítására, pl. a sütik használatának mellőzésére, a sütik letiltására stb., a
                            használt böngésző megfelelő beállításaival. A sütik szelektív, vagy általános
                            tiltásának beállítási lehetőségeiről szóló információk az adott böngésző
                            „Segítség/Help” menüjében találhatóak meg. Ezek segítségével a sütik:
                            <br/><br/>
                            A legtöbb böngésző menüsorában található „Segítség” funkció tájékoztatást
                            nyújt arra vonatkozóan, hogy a böngészőben hogyan lehet a sütiket:
                            <br/><br/>
                            általánosan letiltani;
                            <br/><br/>
                            a sütik elfogadásának módját beállítani (automatikus elfogadás, egyenként
                            kérdezzen rá, stb.);
                            <br/><br/>
                            egyenként letiltani;
                            <br/><br/>
                            egyenként, vagy csoportosan törölni;
                            <br/><br/>
                            egyéb sütikkel kapcsolatos műveleteket elvégezni.
                        </p>
                    </div>
                    <div id="7.5">
                        <strong>7.5 Cookie-k (sütik) kezelése</strong>
                        <ol>
                            <li>
                                Az úgynevezett „jelszóval védett munkamenethez használt cookie”,
                                „bevásárlókosárhoz szükséges cookie-k”, „biztonsági cookie-k”, „Szükségszerű
                                cookie-k”, ”Funkcionális cookie-k”, és a „weboldal statisztikájának kezeléséért
                                felelős cookie-k” használatához nem szükséges előzetes hozzájárulást kérni az
                                érintettektől.
                            </li>
                            <li>
                                Az adatkezelés ténye, a kezelt adatok köre: Egyedi azonosítószám, dátumok,
                                időpontok.
                            </li>
                            <li>
                                Az érintettek köre: A weboldalt látogató valamennyi érintett.
                            </li>
                            <li>
                                Az adatkezelés célja: A felhasználók azonosítása, látogatók nyomon
                                követése, testre szabott működés biztosítása.
                            </li>
                            <li>
                                Az adatkezelés időtartama, az adatok törlésének határideje:
                                <style dangerouslySetInnerHTML={{__html:
                                        "\n.tg{border-collapse:collapse;border-spacing:0;}\n.tg th,.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial,sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg th{font-weight:normal;}\n.tg .tg-y698{background-color:#efefef;}\n"
                                }} />

                                <table className="tg">
                                    <thead>
                                    <tr>
                                        <th className="tg-0pky"><span style={{fontWeight:"bold"}}>Süti típusa</span></th>
                                        <th className="tg-fymr">Adatkezelés jogalapja</th>
                                        <th className="tg-fymr">Adatkezelés időtartama</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="tg-y698">Munkamenet sütik (session), vagy egyéb, a honlap működéséhez elengedhetetlenül szükséges sütik</td>
                                        <td className="tg-y698">GDPR 6. cikk (1) bekezdés f) pontja. Adatkezelő jogos érdeke a weboldal üzemeltetése céljából, a honlap működőképességének, alapvető funkcióinak biztosítása, és a számítógépes rendszer biztonsága.</td>
                                        <td className="tg-y698">Látogatói munkamenet lezárásáig tartó időszak.</td>
                                    </tr>
                                    <tr>
                                        <td className="tg-0pky">Állandó vagy mentett sütik</td>
                                        <td className="tg-0pky">GDPR 6. cikk (1) bekezdés f) pontja. Adatkezelő jogos érdeke a weboldal üzemeltetése céljából, a honlap működőképességének, alapvető funkcióinak biztosítása, és a számítógépes rendszer biztonsága.</td>
                                        <td className="tg-0pky">Az érintett törléséig vagy sütik érvényességi idejéig tárolódik.</td>
                                    </tr>
                                    <tr>
                                        <td className="tg-y698">Statisztikai, marketing sütik</td>
                                        <td className="tg-y698">GDPR 6. cikk (1) bekezdés a) pontja</td>
                                        <td className="tg-y698">1 hónap - 2 év</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </li>
                            <li>
                                Az adatok megismerésére jogosult lehetséges adatkezelők személye: A
                                személyes adatokat az adatkezelő ismerheti meg.
                            </li>
                            <li>
                                Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: Az
                                érintettnek lehetőségük van a cookie-kat törölni a böngészők
                                Eszközök/Beállítások menüjében általában az Adatvédelem menüpont
                                beállításai alatt.
                            </li>
                            <li>
                                A legtöbb böngésző, amelyet felhasználóink használnak, lehetővé teszi
                                annak beállítását, hogy mely cookie-kat kell menteni és lehetővé teszi, hogy
                                (meghatározott) cookie-k újra törlésre kerüljenek. Amennyiben Ön a cookie
                                mentését meghatározott weboldalakon korlátozza vagy harmadik fél cookie-jait
                                nem engedélyezi, úgy ez bizonyos körülmények között oda vezethet, hogy
                                weboldalunk többé nem használható teljes egészében. Itt talál információkat
                                arra vonatkozóan, hogy a szokásos böngészők esetében hogyan tudja a cookie
                                beállításokat testre szabni: <br/>
                                (Google Chrome) <a href="https://support.google.com/chrome/answer/95647?hl=hu" target="_blank">https://support.google.com/chrome/answer/95647?hl=hu</a> <br/>
                                (Internet Explorer) <a href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies</a> <br/>
                                (Firefox) <a href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn" target="_blank">https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn</a> <br/>
                                (Safari) <a href="https://support.apple.com/hu-hu/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/hu-hu/guide/safari/sfri11471/mac</a>
                            </li>

                        </ol>
                    </div>
                    <div id="7.6">
                        <strong>
                            Google Ads konverziókövetés használata
                        </strong>
                        <ol>
                            <li>
                                A „Google Ads” nevű online reklámprogramot használja az adatkezelő,
                                továbbá annak keretein belül igénybe veszi a Google konverziókövető
                                szolgáltatását. A Google konverziókövetés a Google Inc. elemző
                                szolgáltatása (1600 Amphitheatre Parkway, Mountain View, CA 94043,
                                USA; „Google“).
                            </li>
                            <li>
                                Amikor Felhasználó egy weboldalt Google-hirdetés által ér el, akkor egy a
                                konverziókövetéshez szükséges cookie kerül a számítógépére. Ezeknek a
                                cookie-knak az érvényessége korlátozott, és nem tartalmaznak
                                semmilyen személyes adatot, így a Felhasználó nem is azonosítható
                                általuk.
                            </li>
                            <li>
                                Amikor a Felhasználó a weboldal bizonyos oldalait böngészi, és a cookie
                                még nem járt le, akkor a Google és az adatkezelő is láthatja, hogy
                                Felhasználó a hirdetésre kattintott.
                            </li>
                            <li>
                                Minden Google Ads ügyfél másik cookie-t kap, így azokat az Ads
                                ügyfeleinek weboldalain keresztül nem lehet nyomon követni.
                            </li>
                            <li>
                                Az információk – melyeket a konverziókövető cookie-k segítségével
                                szereztek – azt a célt szolgálják, hogy az Ads konverziókövetést választó
                                ügyfeleinek számára konverziós statisztikákat készítsenek. Az ügyfelek
                                így tájékozódnak a hirdetésükre kattintó és konverziókövető címkével
                                ellátott oldalra továbbított felhasználók számáról. Azonban olyan
                                információkhoz nem jutnak hozzá, melyekkel bármelyik felhasználót
                                azonosítani lehetne.
                            </li>
                            <li>
                                Ha nem szeretne részt venni a konverziókövetésben, akkor ezt
                                elutasíthatja azáltal, hogy böngészőjében letiltja a cookie-k telepítésének
                                lehetőségét. Ezután Ön nem fog szerepelni a konverziókövetési
                                statisztikákban.
                            </li>
                            <li>
                                További információ valamint a Google adatvédelmi nyilatkozata az alábbi
                                oldalon érhető el: <a href="https://policies.google.com/privacy?gl=de" target="_blank">https://policies.google.com/privacy?gl=de</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div id="8">
                    <strong>
                        8. Az adatkezelés célja, módja és jogalapja
                    </strong>
                    <div id="8.1">
                        <strong>8.1 Általános adatkezelési irányelvek</strong>
                        <p>
                            Adatkezelő a 3. pontban felsorol Adatkezelésekben a személyes adatok
                            kezelését, minden esetben az adatkezelésben megadott céllal és az ott
                            megadott jogalap alapján, a  felsorol jogszabályok szerint végzi.
                            <br/><br/>
                            A személyes adatok kezelése minden esetben az Érintett önkéntes
                            hozzájárulásával történik, amely hozzájárulást az Érintett bármikor jogosult
                            visszavonni.
                            <br/><br/>
                            Adatkezelő egyes személyes adatokat, jogszabályi kötelezettség folytán,
                            bizonyos esetekben és bizonyos rendhagyó feltételek esetén, köteles az
                            Adatkezelésekben leírtaktól eltérő módon kezelni, átadni, továbbítani, tárolni.
                            Ilyen esetekben Adatkezelő gondoskodik az Érintettek értesítéséről,
                            amennyiben az adott jogszabály előírásai ezt lehetővé teszik, vagy nem tiltják
                            kifejezetten.
                        </p>
                    </div>
                    <div id="8.2">
                        <strong>8.2 Az Adatkezelések jogalapját adó jogszabályok</strong>
                        <p>
                            Adatkezelő a személyes adatok kezelését az alábbi jogszabályok alapján végzi:
                            <br/><br/>
                            GDPR Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április
                            27.) 2013. évi V. törvény – a Polgári Törvénykönyvről (Ptk.);
                            <br/><br/>
                            2013. évi V. törvény – a Polgári Törvénykönyvről (Ptk.);
                        </p>
                    </div>
                </div>
                <div id="9">
                    <strong>9. Az adatok tárolása és biztonsága</strong>
                    <div id="9.1">
                        <strong>9.1 Az adatok fizikai tárolási helyei:</strong>
                        <p>
                            Adatkezelő a személyes adatokat MongoDB adatbázis kezelő tárolja, mely a
                            Digital Ocean rendszerben található.
                            <br/><br/>
                            Név: Digital Ocean LLC.
                            <br/><br/>
                            Cím:  101 Avenue OF The Americas FL 10 New York, NY, 10013-1905 United
                            States
                            <br/><br/>
                            EU/Int VAT: EU528002224
                            <br/><br/>
                            A szerverek fizikai elhelyezkedése: Digital Ocean, NYC1 régió, New York City,
                            USA.
                        </p>
                    </div>
                    <div id="9.2">
                        <strong>9.2 Az adatok informatikai tárolásának módja és logikai biztonsága</strong>
                        <p>
                            Adatkezelő a személyes adatok kezelését elsődlegesen megfelelően kiépített
                            és védett informatika rendszerén végzi. Az informatikai rendszer üzemeltetése
                            során gondoskodik az azon tárolt, feldolgozott és továbbított adatok
                            információbiztonsági alapattribútumainak megfelelő szintjéről, úgymint a kezelt
                            adatok:
                            <br/><br/>
                            sértetlenségéről (Integrity), az adat eredetisége, változatlansága biztosított;
                            <br/><br/>
                            bizalmasságáról (Confidentality), kizárólag az arra jogosultak, a
                            jogosultságukat nem meghaladó mértékben férnek hozzá;
                            <br/><br/>
                            rendelkezésre állásáról (Availability), az adat a jogosultak számára
                            hozzáférhető és elérhető, az elvárt rendelkezésre állási időtartamban. A
                            szükséges informatikai infrastruktúra üzemkészen rendelkezésre áll.
                            <br/><br/>
                            Adatkezelő a kezelt adatokat:
                            <br/><br/>
                            szervezeti, működési, fizikai biztonsági, információbiztonsági védelmi
                            intézkedések strukturált rendszerével védi. Adatkezelő a védelmi
                            intézkedéseket rendszerét és az egyes védelmi intézkedések védelmi szintjeit,
                            a védendő adatok fenyegetéseinek hatására felmerülő kockázatokkal arányos
                            mértékben alakítja ki és működteti. A védelmi intézkedések adatvédelmi
                            szempontból elsősorban a véletlen vagy szándékos törlés, jogosulatlan
                            hozzáférés, szándékos és rosszhiszemű nyilvánosságra hozatal, véletlen
                            nyilvánosságra kerülés, adatvesztés, adatmegsemmisülés elleni védelmet
                            célozzák.
                        </p>
                    </div>
                </div>
                <div id="10">
                    <strong>10. Érintettek jogai</strong>
                    <p>
                        Az Érintett az Adatkezelő által kezelt személyes adataival kapcsolatosan –
                        többek között – a következőkben leírt jogokkal élhet.
                    </p>
                    <div id="10.1">
                        <strong>10.1 Az Érintett hozzáféréshez való joga (GDPR 15. cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra
                            vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha
                            ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz
                            és a következő információkhoz hozzáférést kapjon:
                            <br/><br/>
                            - az adatkezelés céljai;
                            <br/><br/>
                            -az Érintett személyes adatok kategóriái;
                            <br/><br/>
                            -azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a
                            személyes adatokat közölték vagy közölni fogják, ideértve különösen a
                            harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket;
                            <br/><br/>
                            -a személyes adatok tárolásának tervezett időtartama;
                            <br/><br/>
                            -az Érintett joga a helyesbítéshez, törléshez vagy adatkezelés korlátozásához,
                            valamint az adatkezelés elleni tiltakozáshoz;
                            <br/><br/>
                            -panasz benyújtásának joga a felügyeleti hatósághoz;
                            <br/><br/>
                            -ha az adatokat nem az Érintettől gyűjtötték, a forrásukra vonatkozó minden
                            elérhető információ;
                            <br/><br/>
                            -az automatizált döntéshozatal ténye, ideértve a profilalkotást is, valamint az
                            alkalmazott logikára és arra vonatkozó érthető információk, hogy az ilyen
                            adatkezelés milyen jelentőséggel bír, és az Érintettre nézve milyen várható
                            következményekkel jár.
                            <br/><br/>
                            Az Adatkezelő az adatkezelés tárgyát képező személyes adatok másolatát 1
                            példányban az Érintett rendelkezésére bocsátja. Az Érintett által kért további
                            másolatokért az Adatkezelő az adminisztratív költségeken alapuló, észszerű
                            mértékű díjat számíthat fel. Ha az Érintett elektronikus úton nyújtotta be a
                            kérelmet, Adatkezelő az információkat széles körben használt elektronikus
                            formátumban adja át, kivéve ha az Érintett másként kéri, a benyújtástól
                            számított legfeljebb 30 napon belül.
                        </p>
                    </div>
                    <div id="10.2">
                        <strong>10.2 Helyesbítés joga (GDPR 16. cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem
                            nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat, valamint
                            jogosult arra, hogy kérje a hiányos személyes adatok kiegészítését,
                            figyelemmel véve az adatkezelés céljára.
                        </p>
                    </div>
                    <div id="10.3">
                        <strong>10.3 Törléshez való jog (GDPR 17. cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem
                            nélkül törölje a rá vonatkozó személyes adatokat, az Adatkezelő pedig köteles
                            arra, hogy az Érintettre vonatkozó személyes adatokat indokolatlan késedelem
                            nélkül törölje, ha az alábbi indokok valamelyike fennáll:
                            <br/><br/>
                            -személyes adatokra már nincs szükség abból a célból, amelyből azokat
                            gyűjtötték vagy más módon kezelték;
                            <br/><br/>
                            -az Érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az
                            adatkezelésnek nincs más jogalapja;
                            <br/><br/>
                            -az Érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű
                            ok az adatkezelésre;
                            <br/><br/>
                            -a személyes adatokat jogellenesen kezelték;
                            <br/><br/>
                            -a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy tagállami
                            jogban előírt jogi kötelezettség teljesítéséhez törölni kell;
                            <br/><br/>
                            -a személyes adatok gyűjtésére információs társadalommal összefüggő
                            szolgáltatások kínálásával kapcsolatosan került sor.
                            <br/><br/>
                            Az adatok törlése nem kezdeményezhető, ha az adatkezelés szükséges:
                            <br/><br/>
                            -a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása
                            céljából;
                            <br/><br/>
                            -a személyes adatok kezelését előíró, az Adatkezelőre alkalmazandó uniós
                            vagy tagállami jog szerinti kötelezettség teljesítése, illetve közérdekből, vagy
                            az Adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett
                            feladat végrehajtása céljából;
                            <br/><br/>
                            -a népegészségügy területét érintő közérdek alapján;
                            <br/><br/>
                            -Közérdek alapján végzendő archiválási-, tudományos és történelmi kutatási-,
                            vagy statisztikai célból;
                            <br/><br/>
                            -jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.
                        </p>
                    </div>
                    <div id="10.4">
                        <strong>10.4 Az adatkezelés korlátozásához való jog (18. cikk)</strong>
                        <p>
                            Az Érintett kérésére a Adatkezelő korlátozza az adatkezelést, ha az alábbi
                            feltételek valamelyike teljesül:
                            <br/><br/>
                            -az Érintett vitatja a személyes adatok pontosságát, ez esetben a korlátozás
                            arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az Adatkezelő
                            ellenőrizze a személyes adatok pontosságát;
                            <br/><br/>
                            -az adatkezelés jogellenes, és az Érintett ellenzi az adatok törlését, és ehelyett
                            kéri azok felhasználásának korlátozását;
                            <br/><br/>
                            -az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés
                            céljából, de az Érintett igényli azokat jogi igények előterjesztéséhez,
                            érvényesítéséhez vagy védelméhez; vagy
                            <br/><br/>
                            -az Érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az
                            időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az Adatkezelő
                            jogos indokai elsőbbséget élveznek-e az Érintett jogos indokaival szemben.
                            <br/><br/>
                            Ha az adatkezelés korlátozás alá esik, a személyes adatokat a tárolás
                            kivételével csak az Érintett hozzájárulásával, vagy jogi igények
                            előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes
                            vagy jogi személy jogainak védelme érdekében, vagy az Unió, illetve valamely
                            tagállam fontos közérdekéből lehet kezelni
                        </p>
                    </div>
                    <div id="10.5">
                        <strong>10.5 Adathordozáshoz való jog (20. cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy a rá vonatkozó, általa az Adatkezelő
                            rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt,
                            géppel olvasható formátumban megkapja, és ezeket az adatokat egy másik
                            Adatkezelőnek továbbítsa.
                        </p>
                    </div>
                    <div id="10.6">
                        <strong>10.6 Tiltakozás joga (21. cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor
                            tiltakozzon személyes adatainak kezelése ellen, ideértve az említett
                            rendelkezéseken alapuló profilalkotást is. Ebben az esetben az Adatkezelő a
                            személyes adatokat nem kezelheti tovább, kivéve, ha az Adatkezelő bizonyítja,
                            hogy az adatkezelést olyan kényszerítő erejű jogos okok indokolják, amelyek
                            elsőbbséget élveznek az Érintett érdekeivel, jogaival szemben, vagy amelyek
                            jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez
                            kapcsolódnak.
                        </p>
                    </div>
                    <div id="10.7">
                        <strong>10.7 Automatizált döntéshozatal egyedi ügyekben, beleértve a
                            profilalkotást (22.cikk)</strong>
                        <p>
                            Az Érintett jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag
                            automatizált adatkezelésen – ideértve a profilalkotást is – alapuló döntés
                            hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen jelentős
                            mértékben érintené.
                        </p>
                    </div>
                    <div id="10.8">
                        <strong>10.8 Visszavonás joga</strong>
                        <p>
                            Az Érintett jogosult arra, hogy személyes adataira vonatkozó hozzájárulását
                            bármikor visszavonja.
                        </p>
                    </div>
                    <div id="10.9">
                        <strong>10.9 Automatizált döntéshozatal</strong>
                        <p>
                            Nem folytatunk automatizált döntéshozatalon alapuló tevékenységet, így
                            megbízóinkra nem terjedhet ki ilyen döntés hatálya. Profilalkotást sem.
                        </p>
                    </div>
                    <div id="10.10">
                        <strong>10.10 Jogorvoslati lehetőségek</strong>
                        <p>
                            Jogai megsértése esetén az Érintett a megadott elérhetőségeken keresztül,
                            vagy a megadott Adatvédelmi Tisztviselőnél kérhet tájékoztatást,
                            jogorvoslatot, vagy tehet panaszt. Ezek eredménytelensége esetén Érintett
                            jogosult bírósághoz fordulni, illetve megkeresni a Nemzeti Adatvédelmi és
                            Információszabadság Hatóságot.
                        </p>
                    </div>
                    <div id="10.11">
                        <strong>10.11 Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH)
                            elérhetősége</strong>
                        <p>
                            Megnevezés: Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH)
                            <br/><br/>
                            Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/C.
                            <br/><br/>
                            Levelezési cím: 1530 Budapest, Pf.: 5.
                            <br/><br/>
                            Tel: +36 (1) 391-1400
                            <br/><br/>
                            Fax: +36 (1) 391-1410
                            <br/><br/>
                            E-mail: ugyfelszolgalat@naih.hu
                            <br/><br/>
                            Honlap: http://www.naih.hu
                        </p>
                    </div>
                </div>
                <div id="11">
                    <strong>
                        11. Egyéb rendelkezések
                    </strong>
                    <p>
                        Hatósági megkeresés, vagy egyéb jogszabályi kötelezettségen alapuló egyéb
                        szervezet megkeresése esetén Adatkezelő adatkiadásra lehet köteles, vagy
                        arra lehet kötelezhető. Ilyen esetekben Adatkezelő törekszik csak annyi és
                        olyan jellegű személyes adat kiadására, amely az adatkiadási kötelezettség
                        szempontjából feltétlenül szükséges.
                        <br/><br/>
                        Tájékoztatónk 2018. május 25-én lép hatályba és amint olyan új
                        iránymutatások, állásfoglalások, részletszabályok válnak ismertté, amelyek
                        miatt módosítanunk kell, felülvizsgáljuk a tartalmat. Ha vállalkozásunk
                        tevékenységi köre változik, vagy új marketing eszközöket vezetünk be, szintén
                        átírjuk, kiegészítjük, még hosszabbá tesszük.
                        <br/><br/>
                        PONTOS JOGALAPOK ÉS FORRÁSOK Kapcsolatfelvétel a honlapunkra kihelyezett
                        űrlapon keresztül.
                        <br/><br/>
                        Az Európai Parlament és Tanács (EU) 2016/679. rendelete 6. cikk (1) bek. a)
                        pontjával összhangban adatkezelésünk jogalapja a hozzájárulás.
                        Ajánlatkérés a honlapunkra kihelyezett űrlapok segítségével Az Európai
                        Parlament és Tanács (EU) 2016/679. rendelete 6. cikk (1) bek. a) pontjával
                        összhangban adatkezelésünk jogalapja a hozzájárulás. Közvetlenül a nyilvános,
                        elektronikus levelezési címünkre küldött üzenet Az Európai Parlament és
                        Tanács (EU) 2016/679. rendelete 6. cikk (1) bek. a) pontjával összhangban
                        adatkezelésünk jogalapja a hozzájárulás.
                        <br/><br/>
                        Kapcsolatfelvétel a nyilvános telefonszámunk tárcsázásával Az Európai
                        Parlament és Tanács (EU) 2016/679. rendelete 6. cikk (1) bek. a) pontjával
                        összhangban adatkezelésünk jogalapja a hozzájárulás.
                        <br/><br/>
                        Számlázási kötelezettségünk teljesítése Az Európai Parlament és Tanács (EU)
                        2016/679. rendelete 6. cikk (1) bek. c) pont alapján adatkezelésünk jogalapja a
                        jogi kötelezettség, a számvitelről szóló 2000. évi C. tv. 169. § (2) bek. a
                        bizonylatok megőrzésének határidejét részletezi, az általános forgalmi adóról
                        szóló 2007. évi CXXVII. tv. 169. § e) pontja a számla kötelező elemeit; továbbá
                        a 175. § (2) bekezdés a) pont- e-számla hitelesítésének kérdéseit részletezi.
                        <br/><br/>
                        Együtt dolgozunk másokkal Az Európai Parlament és Tanács (EU) 2016/679.
                        rendelete 6. cikk (1) bek. f) pontjával összhangban adatkezelésünk jogalapja a
                        jogos érdek.
                        <br/><br/>
                        Van Facebook üzleti oldalunk Az Európai Parlament és Tanács (EU) 2016/679.
                        rendelete 6. cikk (1) bek. a) pontjával összhangban adatkezelésünk jogalapja a
                        hozzájárulás.
                        <br/><br/>
                        Sütiket is kezel honlapunk Az Európai Parlament és Tanács (EU) 2016/679.
                        rendelete 6. cikk (1) bek. a) pontjával összhangban adatkezelésünk jogalapja a
                        hozzájárulás.
                    </p>
                </div>
            </div>
            <FooterOne/>
        </Layout>
    )
}
export default AdatkezelesIndex