import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Nav = () => {
  const scrollProps = { spy: true, smooth: true, duration: 500 };
  const isHomePage = false;

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        {isHomePage ? (
          <ScrollLink to="happy-pos" offset={-410} {...scrollProps}>
            Termékeink
          </ScrollLink>
        ) : (
          <RouterLink to="/">Termékeink</RouterLink>
        )}
        <ul className="submenu">
          <li>
            <RouterLink to="/termekeink/szoftver">Szoftver</RouterLink>
          </li>
          <li>
            <RouterLink to="/termekeink/hardver">Hardver</RouterLink>
          </li>
          <li>
            <RouterLink
              to="/termekeink/szolgaltatasok"
              onClick={() => {
                /* setTimeout(() => {
                  const extrasList = document.querySelector(".extras-list");
                  window.scrollTo(
                    0,
                    extrasList ? extrasList.offsetTop - 150 : 600
                  );
                }, isHomePage ? 1000 : 100); */
              }}
            >
              Szolgáltatások
            </RouterLink>
          </li>
          {false && (
            <>
              <li>
                <RouterLink to="/termekeink/ettermi-szoftver">
                  Éttermi szoftver
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/termekeink/mobil-pincer-app">
                  Mobil pincér app
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/termekeink/hazhozszallitas-modul">
                  Házhozszállítás modul
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  aria-disabled={"true"}
                  to="#/termekeink/management-statisztikak"
                >
                  Management statisztikák
                </RouterLink>
              </li>
            </>
          )}
        </ul>
      </li>
      <li>
        <RouterLink to="/profilom">Profilom</RouterLink>
      </li>
      {false && (
        <li>
          <RouterLink to="/termekeink/eszkozok">Eszközeink</RouterLink>
        </li>
      )}
      {false && (
        <li>
          <RouterLink to="/profilom">Profilom</RouterLink>
        </li>
      )}
      {false && (
        <li>
          <RouterLink to="/demo">Demo igénylése</RouterLink>
        </li>
      )}
      <li className={"has-droupdown"}>
        <RouterLink to="/szolgaltatasok/arajanlat">Árajánlat</RouterLink>
        <ul className="submenu">
          <li>
            <RouterLink to="/szolgaltatasok/arajanlat/csomag-arak">
              Csomag árak
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/szolgaltatasok/arajanlat/modul-arak">
              Modul árak
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/szolgaltatasok/arajanlat/szolgaltatas-arak">
              Szolgáltatás árak
            </RouterLink>
          </li>
        </ul>
      </li>
      {false && (
          <li>
            <RouterLink to="/szolgaltatasok/partner/regisztracio">
              Regisztráció
            </RouterLink>
        </li>
      )}

      {false && (
        <li>
          <RouterLink to="/partner-program">Partner program</RouterLink>
        </li>
      )}

      <li>
        <RouterLink to="/kapcsolat/ugyfelszolgalat">Ügyfélszolgálat</RouterLink>
      </li>
      {false && (
        <li className="has-droupdown">
          <RouterLink to="/kapcsolat/ugyfelszolgalat">
            Ügyfélszolgálat
          </RouterLink>
          {
            <ul className="submenu">
              <li>
                <RouterLink to="/kapcsolat/ugyfelszolgalat">
                  Kapcsolat velünk
                </RouterLink>
              </li>
              {false && (
                <li>
                  <RouterLink to="/kapcsolat/tudastar">Tudástár</RouterLink>
                </li>
              )}
            </ul>
          }
        </li>
      )}

      {false && (
        <li>
          <RouterLink to="/gyik">GyIK</RouterLink>
        </li>
      )}
    </ul>
  );
};
export default Nav;
