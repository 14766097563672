import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import Layout from "../../common/Layout";
import {Link, useParams} from "react-router-dom";
import {priceFormatter, slugify} from "../../utils";
import SEO from "../../common/SEO";
import axios from "axios";
import loadingAnimation from "../../assets/animations/LoadingHappyData.json"
import Lottie from "react-lottie";

const Breadcrumb = ({deviceType}) => {
    return (
        <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-start">
                            <nav className="edu-breadcrumb-nav">
                                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li className="breadcrumb-item"><Link to={`/termekeink/hardver`}>Hardverek</Link>
                                    </li>
                                    <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                    <li className="breadcrumb-item active"><Link
                                        to={`/termekeink/hardver`}>{deviceType}</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-07.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-13-12.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-5">
                        <img src="/images/shapes/shape-36.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-6">
                        <img src="/images/shapes/shape-05-07.png" alt="Shape Thumb"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DeviceDetails = () => {
    const { deviceSlug } = useParams();
    const [deviceData, setDeviceData] = useState(null);
    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        const fetchDeviceData = async () => {
            try {
                const response = await axios.get('https://api.happygastro.hu/api/billing/plans');
                if (response.status === 200) {
                    const devices = response.data.devices;
                    const device = devices.find(d => slugify(d.name) === deviceSlug);
                    if (device) {
                        setDeviceData(device);
                        setDeviceType(device.type);
                    }
                } else {
                    console.error('Unexpected response format:', response);
                }
            } catch (error) {
                console.error('Error fetching device data:', error);
            }
        };

        fetchDeviceData();
    }, [deviceSlug]);


    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/hardver";
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (<>
            {/*for now its !false for testing */}
            {!deviceData ? <Layout>
                <div style={{paddingTop:'3rem',display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center'}}>
                    <div style={{width: '400px'}}>
                        <Lottie options={defaultOptions}/>
                        <h3>Adatok előkészítése..</h3>
                    </div>
                </div>
                </Layout>
                :
            <Layout>
                {!isHomePage && <SEO title={deviceData.name || ""} description={deviceData.description || ""} />}
                <Breadcrumb deviceType={deviceType || ""}/>
                <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="inner">
                                    <Link to={'/termekeink/hardver'}>
                                        <SectionTitle
                                            classes="text-start"
                                            slogan={deviceType || ""}
                                            title={deviceData.name || ""}
                                        />
                                    </Link>
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                        animateOut="fadeInOut"
                                        className="testimonial-left-image"
                                        animateOnce={true}
                                        style={{ margin: '5rem 0' }}
                                    >
                                        <div className="thumbnail">
                                            <div className="circle-image-wrapper" style={{ width: "34rem" }}>
                                                <img
                                                    style={{ width: '32rem', height: "auto" }}
                                                    src={deviceData.photo || ""}
                                                    alt={deviceData.name || ""}
                                                />
                                                <div className="circle-image">
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div className="col-lg-6" id={"delivery-module"}>
                                <div className="inner">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}
                                    >
                                        <h5>Termék leírása:</h5>
                                        {/*<p className="description mt--40">*/}
                                        {/*    {deviceData.description || ""}*/}
                                        {/*</p>*/}

                                        {/*dangerouslySetInnerHTML replaces the linebreaks in the text with html linebreaks*/}
                                        <p className="description mt--40"
                                           dangerouslySetInnerHTML={{__html: deviceData.description.replace(/\n/g, '<br />')}}/>

                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        className="col-lg-12 p-0"
                                        animateOnce={true}
                                    >
                                        <div className="edu-feature-list">
                                            <div className="content d-flex justify-content-between align-items-center">
                                                <Link to={"/kapcsolat/ugyfelszolgalat"}><button className="edu-btn btn-medium left-icon header-button">
                                                    <i className='bx bx-cart-alt' style={{fontSize: 22, top: 5}}></i>
                                                    Ajánlatkérés / Megrendelés
                                                </button></Link>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                {!isHomePage && <FooterOne />}

            </Layout>}
    </>

    );
};

export default DeviceDetails;