import { createRoot } from "react-dom/client";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { HappyProvider } from "./context/happy_context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const isLocal = false;

if (process.env.REACT_APP_HAPPY_API_URL) {
  window.api = process.env.REACT_APP_HAPPY_API_URL;
} else {
  if (isLocal) window.api = "http://0.0.0.0:8000/api";
  else {
    window.api = "https://api.happygastro.hu/api";
  }
}

window.api = "https://api.happygastro.hu/api"

root.render(
  <HappyProvider>
    <ParallaxProvider>
      <DevSupport
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <App />
      </DevSupport>
    </ParallaxProvider>
  </HappyProvider>
);
