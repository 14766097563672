import React from "react"
import './index.scss'
import {Link} from "react-router-dom";
import pJson from '../../../package.json'

const FooterOne = () => {
    return (
        <div className='footer footer-one' style={{height: '100%'}}>
            <div className="footer-content">
                <div className="top">
                    <div className="column column-1">
                        <Link to='/' className='logo-container'>
                            <img
                                src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                                alt="Happy Gastro Logo"
                                className='logo-img'
                            />
                            <h3 className='t-l'>
                                Happy Gastro
                            </h3>
                        </Link>
                        <p>
                            Happy Gastro Éttermi és Vendéglátós szoftver, raktárkezelő, webáruház vendéglátó, éttermi
                            egysége számára.
                            Happy, Gastro, Étterem, Éttermek, Éttermi szoftver, NTAK, integráció, Foodora, Wolt,
                            ételrendelés, házhozszállítás, Vendéglátós, Vendéglátós szoftver, Bár szoftver,
                            Felszolgáló, Felszolgáló szoftver, Digitális stand, Digitális vendéglátás, Restaurants, POS,
                            terminal, pos terminal, restaurant, restaurant system, restaurant pos
                        </p>
                        <div className="links">
                            <a href="mailto:info@happygastro.hu">
                                <i className="bx bx-mail-send"/>
                            </a>
                            <a href="tel:+3613009105">
                                <i className="bx bx-phone"/>
                            </a>
                            <a href="https://www.facebook.com/gastro.happy" target='_blank' rel="noreferrer">
                                <i className="bx bxl-facebook"/>
                            </a>
                        </div>
                        <div className="aszf">
                            <Link to={'/aszf'}>
                                Általános Szerződési Feltételek
                            </Link>
                        </div>
                        <div className="adatkezelesi-tajekoztato">
                            <Link to={'/adatkezelesi-tajekoztato'}>
                                Adatkezelési tájékoztató
                            </Link>
                        </div>
                        <div className="karrier">
                            <Link to={'/karrier'}>
                                Karrier
                            </Link>
                        </div>
                        <div className="partner-program">
                            <Link to={'/partner-program'}>
                                Partner program
                            </Link>
                        </div>
                        <div className="gyik">
                            <Link to={'/kapcsolat/gyik'}>
                                GyIK
                            </Link>
                        </div>
                        <div className="integrations">
                            <Link to={'/integraciok'}>
                                Integrációk
                            </Link>
                        </div>
                        <br/>
                    </div>
                    <div className="column column-3">
                        <h3>
                            Elérhetőségek
                        </h3>
                        <div className="links">
                            <a href="https://goo.gl/maps/T3hRZM87rtbpGRiY7" target={'_blank'} rel="noreferrer">
                                <i className="bx bx-map-pin"/>
                                <strong>
                                    Szentendre, Duna korzó 11, 2000
                                </strong>
                            </a>
                            <a href="tel:+3613009105">
                                <i className="bx bx-phone-call"/>
                                <strong>
                                    +36 (1) 300 9105
                                </strong>
                            </a>
                            <a href="mailto:info@happygastro.hu">
                                <i className="bx bx-info-circle"/>
                                <strong>
                                    info@happygastro.hu
                                </strong>
                            </a>
                            <a href="mailto:support@happygastro.hu">
                                <i className="bx bx-wrench"/>
                                <strong>
                                    support@happygastro.hu
                                </strong>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="viva-main">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Viva-Wallet_-_Logo_Rgb.png"
                         alt="VivaPayment"/>
                </div>
                <div className="viva">
                    <img
                        src="https://images.prismic.io/vivadotcom/09fb1ac2-f897-4f42-a33c-0f7ec3aad117_Visa-white.png?auto=compress,format&rect=0,0,165,55&w=432&h=144&fm=webp&q=100&fit=fill"
                        alt="Visa"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/4c249be6-c021-4192-a26d-140abe7be84c_Mastercard.svg?w=228&h=144&fm=webp&q=100&fit=fill"
                        alt="MasterCard"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/cc38ce10-ca90-453a-a88d-f87de1ab3261_Group.png?auto=compress,format&rect=0,0,108,108&w=144&h=144&fm=webp&q=100&fit=fill"
                        alt="AmericanExpress"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/6cd02ad6-a8d7-431c-af47-553eb7a6a655_Group.png?auto=compress,format&rect=0,0,143,111&w=186&h=144&fm=webp&q=100&fit=fill"
                        alt="Maestro"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/8baa694c-4bcf-40b8-a039-d989891c2eb1_3Group.png?auto=compress,format&rect=0,0,157,100&w=226&h=144&fm=webp&q=100&fit=fill"
                        alt="ApplePay"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/b3a2f20b-ee66-4e93-91e4-389fe7f51033_Group+79.png?auto=compress,format&rect=0,0,189,101&w=270&h=144&fm=webp&q=100&fit=fill"
                        alt="GooglePay"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/34528dde-3f1f-4794-99c3-e77fa8407223_6Group.png?auto=compress,format&rect=0,0,232,57&w=586&h=144&fm=webp&q=100&fit=fill"
                        alt="PayPal"/>
                    <img
                        src="https://images.prismic.io/vivadotcom/7ee90536-e931-4ac5-980b-27c02e6759e7_2Group.png?auto=compress,format&rect=0,0,152,98&w=224&h=144&fm=webp&q=100&fit=fill"
                        alt="Discover"/>
                </div>
                <div className="bottom">
                    <strong>Copyright © 2020-{new Date().getFullYear()} Happy Gastro Kft. - Minden jog
                        fenntartva.</strong>
                    <div className='happy'>
                        <strong>
                            Üzemelteti a
                        </strong>
                        <div className="watermark">
                            <img src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                                 alt="Happy Gastro Éttermi szoftver"/>
                            <span>
                            Happy Gastro<sup style={{fontSize: '7px', color: '#f1b44c'}}>™</sup>
                        </span>
                        </div>
                        <strong>
                            Éttermi szoftver
                        </strong>
                        <strong>
                            !
                        </strong>
                    </div>
                    <small>v{pJson.version}</small>
                </div>
            </div>
        </div>
    )
}

export default FooterOne;
